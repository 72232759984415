// out: false, main: ../style.scss

///////////////////////////////////////
/////////// Hover Tile ////////////////
///////////////////////////////////////
.tile-grd {
  @media (min-width: $viewport-l){
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .hover-tile-container {
    position: relative;
    height: 300px;
    margin: 1rem;
    background-color: $color-0--l2;
    overflow: hidden;
    &:not(.no-hover){
      cursor: pointer;
    }
    &:not(.no-hover):hover, &:not(.no-hover):focus {
      &::after {
        opacity: 0;
        transition: opacity 0.1s linear;
      }
      .tile-overlay {
        opacity: 1;
        background-color: $color-0--l2;
        transform: translate3d(0%, 0, 0);
        transition: all 0.3s ease-in-out;
      }
    }
    &:not(.no-hover).is-active {
      &::after {
        opacity: 0;
        transition: opacity 0.1s linear;
      }
      .tile-overlay {
        opacity: 1;
        background-color: $color-0--l2;
        transform: translate3d(0%, 0, 0);
        transition: all 0.3s ease-in-out;
      }
    }
    &:not(.no-hover)::after {
      content: "";
      position: absolute;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfMSIgZGF0YS1uYW1lPSJFYmVuZSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5pY29uLXBsdXM8L3RpdGxlPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTI1LC4zOUEyNC42MSwyNC42MSwwLDEsMCw0OS42MSwyNSwyNC42MSwyNC42MSwwLDAsMCwyNSwuMzlabTMuMTYsMjcuNzdWNDQuN0gyMS44NFYyOC4xNkg1LjNWMjEuODRIMjEuODRWNS4zaDYuMzJWMjEuODRINDQuN3Y2LjMyWiIvPjwvc3ZnPg==");
      background-size: contain;
      bottom: 0;
      height: 15px;
      width: 15px;
      margin: 1rem;
      opacity: 1;
      transition: opacity 0.1s linear;
    }
    .tile-img-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      min-height: 1px;
      .tile-img {
        position: relative;
        display: block;
      }
    }
    .tile-overlay {
      position: absolute;
      padding: 1rem;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      overflow-y: auto;
      transform: translate3d(0, -100%, 0);
      background-color: rgba(0,0,0,0);
      transition: all 0.3s ease-in-out;
      color: $white;
      h3 {
        margin: 0;
      }
      p {
        font-size: 0.8rem;
        line-height: 1.5;
        @media (min-width: $viewport-l){
          font-size: 0.8rem;
        }
        @media (min-width: $viewport-xl){
          font-size: 1rem;
        }
      }
    }
  }
}
///////////////////////////////////////
///////////// Content Loader //////////
///////////////////////////////////////
.content-loader {
  .content-loader-el{
    display: none;
  }
}