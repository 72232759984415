// out: false, main: ../style.scss

///////////////////////////////////////
///////// Youtube Video V1 ////////////
///////////////////////////////////////
.yt-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  .yt-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}