.#{$pretty--class-name} {
  input {
    &[disabled] {
      cursor: not-allowed;
      display: none;

      & ~ * {
        opacity: .5;
      }
    }
  }
}
