// out: false, main: ../style.scss

///////////////////////////////////////
/////// CSS Parallax Images ///////////
///////////////////////////////////////

.parallax-main-wrapper {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  @supports(perspective: 1px){
    height: 100vh;
    overflow-x: hidden;
    perspective: 1px;
  }
}
.parallax-image {
  @supports(perspective: 1px){
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      display: block;
      padding-top: 45rem;
    }
  }
  // Internet Explorer 11 
  @media all and (-ms-high-contrast:none) {
    .parallax-image {
      height: 45rem;
      &::before {
        content: none;
      }
    }
  }

  .parallax-image--el {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @supports(perspective: 1px){
      position: absolute;
      top: 0;
      height: 150%;
      transform: translateZ(-0.15px) scale(1.15);
    }
  }
}