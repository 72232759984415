// out: false, main: ../../../style.scss 
$mdi-filename:         "materialdesignicons";
$mdi-font-name:        "Material Design Icons";
$mdi-font-family:      "materialdesignicons";
$mdi-font-weight:      "normal";
$mdi-font-path:        "../icons/material-icons" !default;
$mdi-font-size-base:   24px !default;
$mdi-css-prefix:       mdi !default;
$mdi-version:          "3.9.97" !default;

$mdi-icons: (
  "access-point": F002,
  "access-point-network": F003,
  "access-point-network-off": FBBD,
  "account": F004,
  "account-alert": F005,
  "account-alert-outline": FB2C,
  "account-arrow-left": FB2D,
  "account-arrow-left-outline": FB2E,
  "account-arrow-right": FB2F,
  "account-arrow-right-outline": FB30,
  "account-badge": FD83,
  "account-badge-alert": FD84,
  "account-badge-alert-outline": FD85,
  "account-badge-horizontal": FDF0,
  "account-badge-horizontal-outline": FDF1,
  "account-badge-outline": FD86,
  "account-box": F006,
  "account-box-multiple": F933,
  "account-box-outline": F007,
  "account-card-details": F5D2,
  "account-card-details-outline": FD87,
  "account-check": F008,
  "account-check-outline": FBBE,
  "account-child": FA88,
  "account-child-circle": FA89,
  "account-circle": F009,
  "account-circle-outline": FB31,
  "account-clock": FB32,
  "account-clock-outline": FB33,
  "account-convert": F00A,
  "account-details": F631,
  "account-edit": F6BB,
  "account-group": F848,
  "account-group-outline": FB34,
  "account-heart": F898,
  "account-heart-outline": FBBF,
  "account-key": F00B,
  "account-key-outline": FBC0,
  "account-minus": F00D,
  "account-minus-outline": FAEB,
  "account-multiple": F00E,
  "account-multiple-check": F8C4,
  "account-multiple-minus": F5D3,
  "account-multiple-minus-outline": FBC1,
  "account-multiple-outline": F00F,
  "account-multiple-plus": F010,
  "account-multiple-plus-outline": F7FF,
  "account-network": F011,
  "account-network-outline": FBC2,
  "account-off": F012,
  "account-off-outline": FBC3,
  "account-outline": F013,
  "account-plus": F014,
  "account-plus-outline": F800,
  "account-question": FB35,
  "account-question-outline": FB36,
  "account-remove": F015,
  "account-remove-outline": FAEC,
  "account-search": F016,
  "account-search-outline": F934,
  "account-settings": F630,
  "account-star": F017,
  "account-star-outline": FBC4,
  "account-supervisor": FA8A,
  "account-supervisor-circle": FA8B,
  "account-switch": F019,
  "account-tie": FCBF,
  "accusoft": F849,
  "adchoices": FD1E,
  "adjust": F01A,
  "adobe": F935,
  "air-conditioner": F01B,
  "air-filter": FD1F,
  "air-horn": FD88,
  "air-purifier": FD20,
  "airbag": FBC5,
  "airballoon": F01C,
  "airplane": F01D,
  "airplane-landing": F5D4,
  "airplane-off": F01E,
  "airplane-takeoff": F5D5,
  "airplay": F01F,
  "airport": F84A,
  "alarm": F020,
  "alarm-bell": F78D,
  "alarm-check": F021,
  "alarm-light": F78E,
  "alarm-light-outline": FBC6,
  "alarm-multiple": F022,
  "alarm-note": FE8E,
  "alarm-note-off": FE8F,
  "alarm-off": F023,
  "alarm-plus": F024,
  "alarm-snooze": F68D,
  "album": F025,
  "alert": F026,
  "alert-box": F027,
  "alert-box-outline": FCC0,
  "alert-circle": F028,
  "alert-circle-outline": F5D6,
  "alert-decagram": F6BC,
  "alert-decagram-outline": FCC1,
  "alert-octagon": F029,
  "alert-octagon-outline": FCC2,
  "alert-octagram": F766,
  "alert-octagram-outline": FCC3,
  "alert-outline": F02A,
  "alien": F899,
  "all-inclusive": F6BD,
  "alpha": F02B,
  "alpha-a": 0041,
  "alpha-a-box": FAED,
  "alpha-a-box-outline": FBC7,
  "alpha-a-circle": FBC8,
  "alpha-a-circle-outline": FBC9,
  "alpha-b": 0042,
  "alpha-b-box": FAEE,
  "alpha-b-box-outline": FBCA,
  "alpha-b-circle": FBCB,
  "alpha-b-circle-outline": FBCC,
  "alpha-c": 0043,
  "alpha-c-box": FAEF,
  "alpha-c-box-outline": FBCD,
  "alpha-c-circle": FBCE,
  "alpha-c-circle-outline": FBCF,
  "alpha-d": 0044,
  "alpha-d-box": FAF0,
  "alpha-d-box-outline": FBD0,
  "alpha-d-circle": FBD1,
  "alpha-d-circle-outline": FBD2,
  "alpha-e": 0045,
  "alpha-e-box": FAF1,
  "alpha-e-box-outline": FBD3,
  "alpha-e-circle": FBD4,
  "alpha-e-circle-outline": FBD5,
  "alpha-f": 0046,
  "alpha-f-box": FAF2,
  "alpha-f-box-outline": FBD6,
  "alpha-f-circle": FBD7,
  "alpha-f-circle-outline": FBD8,
  "alpha-g": 0047,
  "alpha-g-box": FAF3,
  "alpha-g-box-outline": FBD9,
  "alpha-g-circle": FBDA,
  "alpha-g-circle-outline": FBDB,
  "alpha-h": 0048,
  "alpha-h-box": FAF4,
  "alpha-h-box-outline": FBDC,
  "alpha-h-circle": FBDD,
  "alpha-h-circle-outline": FBDE,
  "alpha-i": 0049,
  "alpha-i-box": FAF5,
  "alpha-i-box-outline": FBDF,
  "alpha-i-circle": FBE0,
  "alpha-i-circle-outline": FBE1,
  "alpha-j": 004A,
  "alpha-j-box": FAF6,
  "alpha-j-box-outline": FBE2,
  "alpha-j-circle": FBE3,
  "alpha-j-circle-outline": FBE4,
  "alpha-k": 004B,
  "alpha-k-box": FAF7,
  "alpha-k-box-outline": FBE5,
  "alpha-k-circle": FBE6,
  "alpha-k-circle-outline": FBE7,
  "alpha-l": 004C,
  "alpha-l-box": FAF8,
  "alpha-l-box-outline": FBE8,
  "alpha-l-circle": FBE9,
  "alpha-l-circle-outline": FBEA,
  "alpha-m": 004D,
  "alpha-m-box": FAF9,
  "alpha-m-box-outline": FBEB,
  "alpha-m-circle": FBEC,
  "alpha-m-circle-outline": FBED,
  "alpha-n": 004E,
  "alpha-n-box": FAFA,
  "alpha-n-box-outline": FBEE,
  "alpha-n-circle": FBEF,
  "alpha-n-circle-outline": FBF0,
  "alpha-o": 004F,
  "alpha-o-box": FAFB,
  "alpha-o-box-outline": FBF1,
  "alpha-o-circle": FBF2,
  "alpha-o-circle-outline": FBF3,
  "alpha-p": 0050,
  "alpha-p-box": FAFC,
  "alpha-p-box-outline": FBF4,
  "alpha-p-circle": FBF5,
  "alpha-p-circle-outline": FBF6,
  "alpha-q": 0051,
  "alpha-q-box": FAFD,
  "alpha-q-box-outline": FBF7,
  "alpha-q-circle": FBF8,
  "alpha-q-circle-outline": FBF9,
  "alpha-r": 0052,
  "alpha-r-box": FAFE,
  "alpha-r-box-outline": FBFA,
  "alpha-r-circle": FBFB,
  "alpha-r-circle-outline": FBFC,
  "alpha-s": 0053,
  "alpha-s-box": FAFF,
  "alpha-s-box-outline": FBFD,
  "alpha-s-circle": FBFE,
  "alpha-s-circle-outline": FBFF,
  "alpha-t": 0054,
  "alpha-t-box": FB00,
  "alpha-t-box-outline": FC00,
  "alpha-t-circle": FC01,
  "alpha-t-circle-outline": FC02,
  "alpha-u": 0055,
  "alpha-u-box": FB01,
  "alpha-u-box-outline": FC03,
  "alpha-u-circle": FC04,
  "alpha-u-circle-outline": FC05,
  "alpha-v": 0056,
  "alpha-v-box": FB02,
  "alpha-v-box-outline": FC06,
  "alpha-v-circle": FC07,
  "alpha-v-circle-outline": FC08,
  "alpha-w": 0057,
  "alpha-w-box": FB03,
  "alpha-w-box-outline": FC09,
  "alpha-w-circle": FC0A,
  "alpha-w-circle-outline": FC0B,
  "alpha-x": 0058,
  "alpha-x-box": FB04,
  "alpha-x-box-outline": FC0C,
  "alpha-x-circle": FC0D,
  "alpha-x-circle-outline": FC0E,
  "alpha-y": 0059,
  "alpha-y-box": FB05,
  "alpha-y-box-outline": FC0F,
  "alpha-y-circle": FC10,
  "alpha-y-circle-outline": FC11,
  "alpha-z": 005A,
  "alpha-z-box": FB06,
  "alpha-z-box-outline": FC12,
  "alpha-z-circle": FC13,
  "alpha-z-circle-outline": FC14,
  "alphabetical": F02C,
  "altimeter": F5D7,
  "amazon": F02D,
  "amazon-alexa": F8C5,
  "amazon-drive": F02E,
  "ambulance": F02F,
  "ammunition": FCC4,
  "ampersand": FA8C,
  "amplifier": F030,
  "anchor": F031,
  "android": F032,
  "android-auto": FA8D,
  "android-debug-bridge": F033,
  "android-head": F78F,
  "android-messages": FD21,
  "android-studio": F034,
  "angle-acute": F936,
  "angle-obtuse": F937,
  "angle-right": F938,
  "angular": F6B1,
  "angularjs": F6BE,
  "animation": F5D8,
  "animation-outline": FA8E,
  "animation-play": F939,
  "animation-play-outline": FA8F,
  "anvil": F89A,
  "apple": F035,
  "apple-finder": F036,
  "apple-icloud": F038,
  "apple-ios": F037,
  "apple-keyboard-caps": F632,
  "apple-keyboard-command": F633,
  "apple-keyboard-control": F634,
  "apple-keyboard-option": F635,
  "apple-keyboard-shift": F636,
  "apple-safari": F039,
  "application": F614,
  "application-export": FD89,
  "application-import": FD8A,
  "apps": F03B,
  "apps-box": FD22,
  "arch": F8C6,
  "archive": F03C,
  "arrange-bring-forward": F03D,
  "arrange-bring-to-front": F03E,
  "arrange-send-backward": F03F,
  "arrange-send-to-back": F040,
  "arrow-all": F041,
  "arrow-bottom-left": F042,
  "arrow-bottom-left-bold-outline": F9B6,
  "arrow-bottom-left-thick": F9B7,
  "arrow-bottom-right": F043,
  "arrow-bottom-right-bold-outline": F9B8,
  "arrow-bottom-right-thick": F9B9,
  "arrow-collapse": F615,
  "arrow-collapse-all": F044,
  "arrow-collapse-down": F791,
  "arrow-collapse-horizontal": F84B,
  "arrow-collapse-left": F792,
  "arrow-collapse-right": F793,
  "arrow-collapse-up": F794,
  "arrow-collapse-vertical": F84C,
  "arrow-decision": F9BA,
  "arrow-decision-auto": F9BB,
  "arrow-decision-auto-outline": F9BC,
  "arrow-decision-outline": F9BD,
  "arrow-down": F045,
  "arrow-down-bold": F72D,
  "arrow-down-bold-box": F72E,
  "arrow-down-bold-box-outline": F72F,
  "arrow-down-bold-circle": F047,
  "arrow-down-bold-circle-outline": F048,
  "arrow-down-bold-hexagon-outline": F049,
  "arrow-down-bold-outline": F9BE,
  "arrow-down-box": F6BF,
  "arrow-down-circle": FCB7,
  "arrow-down-circle-outline": FCB8,
  "arrow-down-drop-circle": F04A,
  "arrow-down-drop-circle-outline": F04B,
  "arrow-down-thick": F046,
  "arrow-expand": F616,
  "arrow-expand-all": F04C,
  "arrow-expand-down": F795,
  "arrow-expand-horizontal": F84D,
  "arrow-expand-left": F796,
  "arrow-expand-right": F797,
  "arrow-expand-up": F798,
  "arrow-expand-vertical": F84E,
  "arrow-left": F04D,
  "arrow-left-bold": F730,
  "arrow-left-bold-box": F731,
  "arrow-left-bold-box-outline": F732,
  "arrow-left-bold-circle": F04F,
  "arrow-left-bold-circle-outline": F050,
  "arrow-left-bold-hexagon-outline": F051,
  "arrow-left-bold-outline": F9BF,
  "arrow-left-box": F6C0,
  "arrow-left-circle": FCB9,
  "arrow-left-circle-outline": FCBA,
  "arrow-left-drop-circle": F052,
  "arrow-left-drop-circle-outline": F053,
  "arrow-left-right": FE90,
  "arrow-left-right-bold": FE91,
  "arrow-left-right-bold-outline": F9C0,
  "arrow-left-thick": F04E,
  "arrow-right": F054,
  "arrow-right-bold": F733,
  "arrow-right-bold-box": F734,
  "arrow-right-bold-box-outline": F735,
  "arrow-right-bold-circle": F056,
  "arrow-right-bold-circle-outline": F057,
  "arrow-right-bold-hexagon-outline": F058,
  "arrow-right-bold-outline": F9C1,
  "arrow-right-box": F6C1,
  "arrow-right-circle": FCBB,
  "arrow-right-circle-outline": FCBC,
  "arrow-right-drop-circle": F059,
  "arrow-right-drop-circle-outline": F05A,
  "arrow-right-thick": F055,
  "arrow-split-horizontal": F93A,
  "arrow-split-vertical": F93B,
  "arrow-top-left": F05B,
  "arrow-top-left-bold-outline": F9C2,
  "arrow-top-left-bottom-right": FE92,
  "arrow-top-left-bottom-right-bold": FE93,
  "arrow-top-left-thick": F9C3,
  "arrow-top-right": F05C,
  "arrow-top-right-bold-outline": F9C4,
  "arrow-top-right-bottom-left": FE94,
  "arrow-top-right-bottom-left-bold": FE95,
  "arrow-top-right-thick": F9C5,
  "arrow-up": F05D,
  "arrow-up-bold": F736,
  "arrow-up-bold-box": F737,
  "arrow-up-bold-box-outline": F738,
  "arrow-up-bold-circle": F05F,
  "arrow-up-bold-circle-outline": F060,
  "arrow-up-bold-hexagon-outline": F061,
  "arrow-up-bold-outline": F9C6,
  "arrow-up-box": F6C2,
  "arrow-up-circle": FCBD,
  "arrow-up-circle-outline": FCBE,
  "arrow-up-down": FE96,
  "arrow-up-down-bold": FE97,
  "arrow-up-down-bold-outline": F9C7,
  "arrow-up-drop-circle": F062,
  "arrow-up-drop-circle-outline": F063,
  "arrow-up-thick": F05E,
  "artist": F802,
  "artist-outline": FCC5,
  "artstation": FB37,
  "aspect-ratio": FA23,
  "assistant": F064,
  "asterisk": F6C3,
  "at": F065,
  "atlassian": F803,
  "atm": FD23,
  "atom": F767,
  "atom-variant": FE98,
  "attachment": F066,
  "audio-video": F93C,
  "audiobook": F067,
  "augmented-reality": F84F,
  "auto-fix": F068,
  "auto-upload": F069,
  "autorenew": F06A,
  "av-timer": F06B,
  "aws": FDF2,
  "axe": F8C7,
  "axis": FD24,
  "axis-arrow": FD25,
  "axis-arrow-lock": FD26,
  "axis-lock": FD27,
  "axis-x-arrow": FD28,
  "axis-x-arrow-lock": FD29,
  "axis-x-rotate-clockwise": FD2A,
  "axis-x-rotate-counterclockwise": FD2B,
  "axis-x-y-arrow-lock": FD2C,
  "axis-y-arrow": FD2D,
  "axis-y-arrow-lock": FD2E,
  "axis-y-rotate-clockwise": FD2F,
  "axis-y-rotate-counterclockwise": FD30,
  "axis-z-arrow": FD31,
  "axis-z-arrow-lock": FD32,
  "axis-z-rotate-clockwise": FD33,
  "axis-z-rotate-counterclockwise": FD34,
  "azure": F804,
  "babel": FA24,
  "baby": F06C,
  "baby-bottle": FF56,
  "baby-bottle-outline": FF57,
  "baby-buggy": F68E,
  "baby-face": FE99,
  "baby-face-outline": FE9A,
  "backburger": F06D,
  "backspace": F06E,
  "backspace-outline": FB38,
  "backspace-reverse": FE9B,
  "backspace-reverse-outline": FE9C,
  "backup-restore": F06F,
  "bacteria": FEF2,
  "bacteria-outline": FEF3,
  "badminton": F850,
  "bag-carry-on": FF58,
  "bag-carry-on-check": FD41,
  "bag-carry-on-off": FF59,
  "bag-checked": FF5A,
  "bag-personal": FDF3,
  "bag-personal-off": FDF4,
  "bag-personal-off-outline": FDF5,
  "bag-personal-outline": FDF6,
  "baguette": FF5B,
  "balloon": FA25,
  "ballot": F9C8,
  "ballot-outline": F9C9,
  "ballot-recount": FC15,
  "ballot-recount-outline": FC16,
  "bandage": FD8B,
  "bandcamp": F674,
  "bank": F070,
  "bank-minus": FD8C,
  "bank-outline": FE9D,
  "bank-plus": FD8D,
  "bank-remove": FD8E,
  "bank-transfer": FA26,
  "bank-transfer-in": FA27,
  "bank-transfer-out": FA28,
  "barcode": F071,
  "barcode-scan": F072,
  "barley": F073,
  "barley-off": FB39,
  "barn": FB3A,
  "barrel": F074,
  "baseball": F851,
  "baseball-bat": F852,
  "basecamp": F075,
  "basket": F076,
  "basket-fill": F077,
  "basket-unfill": F078,
  "basketball": F805,
  "basketball-hoop": FC17,
  "basketball-hoop-outline": FC18,
  "bat": FB3B,
  "battery": F079,
  "battery-10": F07A,
  "battery-10-bluetooth": F93D,
  "battery-20": F07B,
  "battery-20-bluetooth": F93E,
  "battery-30": F07C,
  "battery-30-bluetooth": F93F,
  "battery-40": F07D,
  "battery-40-bluetooth": F940,
  "battery-50": F07E,
  "battery-50-bluetooth": F941,
  "battery-60": F07F,
  "battery-60-bluetooth": F942,
  "battery-70": F080,
  "battery-70-bluetooth": F943,
  "battery-80": F081,
  "battery-80-bluetooth": F944,
  "battery-90": F082,
  "battery-90-bluetooth": F945,
  "battery-alert": F083,
  "battery-alert-bluetooth": F946,
  "battery-bluetooth": F947,
  "battery-bluetooth-variant": F948,
  "battery-charging": F084,
  "battery-charging-10": F89B,
  "battery-charging-100": F085,
  "battery-charging-20": F086,
  "battery-charging-30": F087,
  "battery-charging-40": F088,
  "battery-charging-50": F89C,
  "battery-charging-60": F089,
  "battery-charging-70": F89D,
  "battery-charging-80": F08A,
  "battery-charging-90": F08B,
  "battery-charging-outline": F89E,
  "battery-charging-wireless": F806,
  "battery-charging-wireless-10": F807,
  "battery-charging-wireless-20": F808,
  "battery-charging-wireless-30": F809,
  "battery-charging-wireless-40": F80A,
  "battery-charging-wireless-50": F80B,
  "battery-charging-wireless-60": F80C,
  "battery-charging-wireless-70": F80D,
  "battery-charging-wireless-80": F80E,
  "battery-charging-wireless-90": F80F,
  "battery-charging-wireless-alert": F810,
  "battery-charging-wireless-outline": F811,
  "battery-minus": F08C,
  "battery-negative": F08D,
  "battery-outline": F08E,
  "battery-plus": F08F,
  "battery-positive": F090,
  "battery-unknown": F091,
  "battery-unknown-bluetooth": F949,
  "battlenet": FB3C,
  "beach": F092,
  "beaker": FCC6,
  "beaker-outline": F68F,
  "beats": F097,
  "bed-empty": F89F,
  "beer": F098,
  "behance": F099,
  "bell": F09A,
  "bell-alert": FD35,
  "bell-alert-outline": FE9E,
  "bell-circle": FD36,
  "bell-circle-outline": FD37,
  "bell-off": F09B,
  "bell-off-outline": FA90,
  "bell-outline": F09C,
  "bell-plus": F09D,
  "bell-plus-outline": FA91,
  "bell-ring": F09E,
  "bell-ring-outline": F09F,
  "bell-sleep": F0A0,
  "bell-sleep-outline": FA92,
  "beta": F0A1,
  "betamax": F9CA,
  "biathlon": FDF7,
  "bible": F0A2,
  "bike": F0A3,
  "billiards": FB3D,
  "billiards-rack": FB3E,
  "bing": F0A4,
  "binoculars": F0A5,
  "bio": F0A6,
  "biohazard": F0A7,
  "bitbucket": F0A8,
  "bitcoin": F812,
  "black-mesa": F0A9,
  "blackberry": F0AA,
  "blender": FCC7,
  "blender-software": F0AB,
  "blinds": F0AC,
  "block-helper": F0AD,
  "blogger": F0AE,
  "blood-bag": FCC8,
  "bluetooth": F0AF,
  "bluetooth-audio": F0B0,
  "bluetooth-connect": F0B1,
  "bluetooth-off": F0B2,
  "bluetooth-settings": F0B3,
  "bluetooth-transfer": F0B4,
  "blur": F0B5,
  "blur-linear": F0B6,
  "blur-off": F0B7,
  "blur-radial": F0B8,
  "bolnisi-cross": FCC9,
  "bolt": FD8F,
  "bomb": F690,
  "bomb-off": F6C4,
  "bone": F0B9,
  "book": F0BA,
  "book-lock": F799,
  "book-lock-open": F79A,
  "book-minus": F5D9,
  "book-minus-multiple": FA93,
  "book-multiple": F0BB,
  "book-open": F0BD,
  "book-open-outline": FB3F,
  "book-open-page-variant": F5DA,
  "book-open-variant": F0BE,
  "book-outline": FB40,
  "book-play": FE9F,
  "book-play-outline": FEA0,
  "book-plus": F5DB,
  "book-plus-multiple": FA94,
  "book-remove": FA96,
  "book-remove-multiple": FA95,
  "book-search": FEA1,
  "book-search-outline": FEA2,
  "book-variant": F0BF,
  "book-variant-multiple": F0BC,
  "bookmark": F0C0,
  "bookmark-check": F0C1,
  "bookmark-minus": F9CB,
  "bookmark-minus-outline": F9CC,
  "bookmark-multiple": FDF8,
  "bookmark-multiple-outline": FDF9,
  "bookmark-music": F0C2,
  "bookmark-off": F9CD,
  "bookmark-off-outline": F9CE,
  "bookmark-outline": F0C3,
  "bookmark-plus": F0C5,
  "bookmark-plus-outline": F0C4,
  "bookmark-remove": F0C6,
  "boom-gate": FEA3,
  "boom-gate-alert": FEA4,
  "boom-gate-alert-outline": FEA5,
  "boom-gate-down": FEA6,
  "boom-gate-down-outline": FEA7,
  "boom-gate-outline": FEA8,
  "boom-gate-up": FEA9,
  "boom-gate-up-outline": FEAA,
  "boombox": F5DC,
  "bootstrap": F6C5,
  "border-all": F0C7,
  "border-all-variant": F8A0,
  "border-bottom": F0C8,
  "border-bottom-variant": F8A1,
  "border-color": F0C9,
  "border-horizontal": F0CA,
  "border-inside": F0CB,
  "border-left": F0CC,
  "border-left-variant": F8A2,
  "border-none": F0CD,
  "border-none-variant": F8A3,
  "border-outside": F0CE,
  "border-right": F0CF,
  "border-right-variant": F8A4,
  "border-style": F0D0,
  "border-top": F0D1,
  "border-top-variant": F8A5,
  "border-vertical": F0D2,
  "bottle-wine": F853,
  "bow-tie": F677,
  "bowl": F617,
  "bowling": F0D3,
  "box": F0D4,
  "box-cutter": F0D5,
  "box-shadow": F637,
  "boxing-glove": FB41,
  "braille": F9CF,
  "brain": F9D0,
  "bread-slice": FCCA,
  "bread-slice-outline": FCCB,
  "bridge": F618,
  "briefcase": F0D6,
  "briefcase-account": FCCC,
  "briefcase-account-outline": FCCD,
  "briefcase-check": F0D7,
  "briefcase-download": F0D8,
  "briefcase-download-outline": FC19,
  "briefcase-edit": FA97,
  "briefcase-edit-outline": FC1A,
  "briefcase-minus": FA29,
  "briefcase-minus-outline": FC1B,
  "briefcase-outline": F813,
  "briefcase-plus": FA2A,
  "briefcase-plus-outline": FC1C,
  "briefcase-remove": FA2B,
  "briefcase-remove-outline": FC1D,
  "briefcase-search": FA2C,
  "briefcase-search-outline": FC1E,
  "briefcase-upload": F0D9,
  "briefcase-upload-outline": FC1F,
  "brightness-1": F0DA,
  "brightness-2": F0DB,
  "brightness-3": F0DC,
  "brightness-4": F0DD,
  "brightness-5": F0DE,
  "brightness-6": F0DF,
  "brightness-7": F0E0,
  "brightness-auto": F0E1,
  "brightness-percent": FCCE,
  "broom": F0E2,
  "brush": F0E3,
  "buddhism": F94A,
  "buffer": F619,
  "bug": F0E4,
  "bug-check": FA2D,
  "bug-check-outline": FA2E,
  "bug-outline": FA2F,
  "bugle": FD90,
  "bulldozer": FB07,
  "bullet": FCCF,
  "bulletin-board": F0E5,
  "bullhorn": F0E6,
  "bullhorn-outline": FB08,
  "bullseye": F5DD,
  "bullseye-arrow": F8C8,
  "bus": F0E7,
  "bus-alert": FA98,
  "bus-articulated-end": F79B,
  "bus-articulated-front": F79C,
  "bus-clock": F8C9,
  "bus-double-decker": F79D,
  "bus-multiple": FF5C,
  "bus-school": F79E,
  "bus-side": F79F,
  "cached": F0E8,
  "cactus": FD91,
  "cake": F0E9,
  "cake-layered": F0EA,
  "cake-variant": F0EB,
  "calculator": F0EC,
  "calculator-variant": FA99,
  "calendar": F0ED,
  "calendar-account": FEF4,
  "calendar-account-outline": FEF5,
  "calendar-alert": FA30,
  "calendar-blank": F0EE,
  "calendar-blank-outline": FB42,
  "calendar-check": F0EF,
  "calendar-check-outline": FC20,
  "calendar-clock": F0F0,
  "calendar-edit": F8A6,
  "calendar-export": FB09,
  "calendar-heart": F9D1,
  "calendar-import": FB0A,
  "calendar-minus": FD38,
  "calendar-month": FDFA,
  "calendar-month-outline": FDFB,
  "calendar-multiple": F0F1,
  "calendar-multiple-check": F0F2,
  "calendar-multiselect": FA31,
  "calendar-outline": FB43,
  "calendar-plus": F0F3,
  "calendar-question": F691,
  "calendar-range": F678,
  "calendar-range-outline": FB44,
  "calendar-remove": F0F4,
  "calendar-remove-outline": FC21,
  "calendar-repeat": FEAB,
  "calendar-repeat-outline": FEAC,
  "calendar-search": F94B,
  "calendar-star": F9D2,
  "calendar-text": F0F5,
  "calendar-text-outline": FC22,
  "calendar-today": F0F6,
  "calendar-week": FA32,
  "calendar-week-begin": FA33,
  "calendar-weekend": FEF6,
  "calendar-weekend-outline": FEF7,
  "call-made": F0F7,
  "call-merge": F0F8,
  "call-missed": F0F9,
  "call-received": F0FA,
  "call-split": F0FB,
  "camcorder": F0FC,
  "camcorder-box": F0FD,
  "camcorder-box-off": F0FE,
  "camcorder-off": F0FF,
  "camera": F100,
  "camera-account": F8CA,
  "camera-burst": F692,
  "camera-control": FB45,
  "camera-enhance": F101,
  "camera-enhance-outline": FB46,
  "camera-front": F102,
  "camera-front-variant": F103,
  "camera-gopro": F7A0,
  "camera-image": F8CB,
  "camera-iris": F104,
  "camera-metering-center": F7A1,
  "camera-metering-matrix": F7A2,
  "camera-metering-partial": F7A3,
  "camera-metering-spot": F7A4,
  "camera-off": F5DF,
  "camera-outline": FD39,
  "camera-party-mode": F105,
  "camera-plus": FEF8,
  "camera-plus-outline": FEF9,
  "camera-rear": F106,
  "camera-rear-variant": F107,
  "camera-retake": FDFC,
  "camera-retake-outline": FDFD,
  "camera-switch": F108,
  "camera-timer": F109,
  "camera-wireless": FD92,
  "camera-wireless-outline": FD93,
  "campfire": FEFA,
  "cancel": F739,
  "candle": F5E2,
  "candycane": F10A,
  "cannabis": F7A5,
  "caps-lock": FA9A,
  "car": F10B,
  "car-back": FDFE,
  "car-battery": F10C,
  "car-brake-abs": FC23,
  "car-brake-alert": FC24,
  "car-brake-hold": FD3A,
  "car-brake-parking": FD3B,
  "car-connected": F10D,
  "car-convertible": F7A6,
  "car-cruise-control": FD3C,
  "car-defrost-front": FD3D,
  "car-defrost-rear": FD3E,
  "car-door": FB47,
  "car-electric": FB48,
  "car-esp": FC25,
  "car-estate": F7A7,
  "car-hatchback": F7A8,
  "car-key": FB49,
  "car-light-dimmed": FC26,
  "car-light-fog": FC27,
  "car-light-high": FC28,
  "car-limousine": F8CC,
  "car-multiple": FB4A,
  "car-off": FDFF,
  "car-parking-lights": FD3F,
  "car-pickup": F7A9,
  "car-shift-pattern": FF5D,
  "car-side": F7AA,
  "car-sports": F7AB,
  "car-tire-alert": FC29,
  "car-traction-control": FD40,
  "car-wash": F10E,
  "caravan": F7AC,
  "card": FB4B,
  "card-bulleted": FB4C,
  "card-bulleted-off": FB4D,
  "card-bulleted-off-outline": FB4E,
  "card-bulleted-outline": FB4F,
  "card-bulleted-settings": FB50,
  "card-bulleted-settings-outline": FB51,
  "card-outline": FB52,
  "card-text": FB53,
  "card-text-outline": FB54,
  "cards": F638,
  "cards-club": F8CD,
  "cards-diamond": F8CE,
  "cards-heart": F8CF,
  "cards-outline": F639,
  "cards-playing-outline": F63A,
  "cards-spade": F8D0,
  "cards-variant": F6C6,
  "carrot": F10F,
  "cart": F110,
  "cart-arrow-down": FD42,
  "cart-arrow-right": FC2A,
  "cart-arrow-up": FD43,
  "cart-minus": FD44,
  "cart-off": F66B,
  "cart-outline": F111,
  "cart-plus": F112,
  "cart-remove": FD45,
  "case-sensitive-alt": F113,
  "cash": F114,
  "cash-100": F115,
  "cash-marker": FD94,
  "cash-multiple": F116,
  "cash-refund": FA9B,
  "cash-register": FCD0,
  "cash-usd": F117,
  "cassette": F9D3,
  "cast": F118,
  "cast-connected": F119,
  "cast-education": FE6D,
  "cast-off": F789,
  "castle": F11A,
  "cat": F11B,
  "cctv": F7AD,
  "ceiling-light": F768,
  "cellphone": F11C,
  "cellphone-android": F11D,
  "cellphone-arrow-down": F9D4,
  "cellphone-basic": F11E,
  "cellphone-dock": F11F,
  "cellphone-erase": F94C,
  "cellphone-information": FF5E,
  "cellphone-iphone": F120,
  "cellphone-key": F94D,
  "cellphone-link": F121,
  "cellphone-link-off": F122,
  "cellphone-lock": F94E,
  "cellphone-message": F8D2,
  "cellphone-nfc": FEAD,
  "cellphone-off": F94F,
  "cellphone-screenshot": FA34,
  "cellphone-settings": F123,
  "cellphone-settings-variant": F950,
  "cellphone-sound": F951,
  "cellphone-text": F8D1,
  "cellphone-wireless": F814,
  "celtic-cross": FCD1,
  "certificate": F124,
  "chair-rolling": FFBA,
  "chair-school": F125,
  "charity": FC2B,
  "chart-arc": F126,
  "chart-areaspline": F127,
  "chart-areaspline-variant": FEAE,
  "chart-bar": F128,
  "chart-bar-stacked": F769,
  "chart-bell-curve": FC2C,
  "chart-bubble": F5E3,
  "chart-donut": F7AE,
  "chart-donut-variant": F7AF,
  "chart-gantt": F66C,
  "chart-histogram": F129,
  "chart-line": F12A,
  "chart-line-stacked": F76A,
  "chart-line-variant": F7B0,
  "chart-multiline": F8D3,
  "chart-pie": F12B,
  "chart-scatter-plot": FEAF,
  "chart-scatter-plot-hexbin": F66D,
  "chart-timeline": F66E,
  "chart-timeline-variant": FEB0,
  "chart-tree": FEB1,
  "chat": FB55,
  "chat-alert": FB56,
  "chat-outline": FEFB,
  "chat-processing": FB57,
  "check": F12C,
  "check-all": F12D,
  "check-bold": FE6E,
  "check-box-multiple-outline": FC2D,
  "check-box-outline": FC2E,
  "check-circle": F5E0,
  "check-circle-outline": F5E1,
  "check-decagram": F790,
  "check-network": FC2F,
  "check-network-outline": FC30,
  "check-outline": F854,
  "check-underline": FE70,
  "check-underline-circle": FE71,
  "check-underline-circle-outline": FE72,
  "checkbook": FA9C,
  "checkbox-blank": F12E,
  "checkbox-blank-circle": F12F,
  "checkbox-blank-circle-outline": F130,
  "checkbox-blank-outline": F131,
  "checkbox-intermediate": F855,
  "checkbox-marked": F132,
  "checkbox-marked-circle": F133,
  "checkbox-marked-circle-outline": F134,
  "checkbox-marked-outline": F135,
  "checkbox-multiple-blank": F136,
  "checkbox-multiple-blank-circle": F63B,
  "checkbox-multiple-blank-circle-outline": F63C,
  "checkbox-multiple-blank-outline": F137,
  "checkbox-multiple-marked": F138,
  "checkbox-multiple-marked-circle": F63D,
  "checkbox-multiple-marked-circle-outline": F63E,
  "checkbox-multiple-marked-outline": F139,
  "checkerboard": F13A,
  "chef-hat": FB58,
  "chemical-weapon": F13B,
  "chess-bishop": F85B,
  "chess-king": F856,
  "chess-knight": F857,
  "chess-pawn": F858,
  "chess-queen": F859,
  "chess-rook": F85A,
  "chevron-double-down": F13C,
  "chevron-double-left": F13D,
  "chevron-double-right": F13E,
  "chevron-double-up": F13F,
  "chevron-down": F140,
  "chevron-down-box": F9D5,
  "chevron-down-box-outline": F9D6,
  "chevron-down-circle": FB0B,
  "chevron-down-circle-outline": FB0C,
  "chevron-left": F141,
  "chevron-left-box": F9D7,
  "chevron-left-box-outline": F9D8,
  "chevron-left-circle": FB0D,
  "chevron-left-circle-outline": FB0E,
  "chevron-right": F142,
  "chevron-right-box": F9D9,
  "chevron-right-box-outline": F9DA,
  "chevron-right-circle": FB0F,
  "chevron-right-circle-outline": FB10,
  "chevron-triple-down": FD95,
  "chevron-triple-left": FD96,
  "chevron-triple-right": FD97,
  "chevron-triple-up": FD98,
  "chevron-up": F143,
  "chevron-up-box": F9DB,
  "chevron-up-box-outline": F9DC,
  "chevron-up-circle": FB11,
  "chevron-up-circle-outline": FB12,
  "chili-hot": F7B1,
  "chili-medium": F7B2,
  "chili-mild": F7B3,
  "chip": F61A,
  "christianity": F952,
  "christianity-outline": FCD2,
  "church": F144,
  "circle": F764,
  "circle-double": FEB2,
  "circle-edit-outline": F8D4,
  "circle-expand": FEB3,
  "circle-medium": F9DD,
  "circle-outline": F765,
  "circle-slice-1": FA9D,
  "circle-slice-2": FA9E,
  "circle-slice-3": FA9F,
  "circle-slice-4": FAA0,
  "circle-slice-5": FAA1,
  "circle-slice-6": FAA2,
  "circle-slice-7": FAA3,
  "circle-slice-8": FAA4,
  "circle-small": F9DE,
  "circular-saw": FE73,
  "cisco-webex": F145,
  "city": F146,
  "city-variant": FA35,
  "city-variant-outline": FA36,
  "clipboard": F147,
  "clipboard-account": F148,
  "clipboard-account-outline": FC31,
  "clipboard-alert": F149,
  "clipboard-alert-outline": FCD3,
  "clipboard-arrow-down": F14A,
  "clipboard-arrow-down-outline": FC32,
  "clipboard-arrow-left": F14B,
  "clipboard-arrow-left-outline": FCD4,
  "clipboard-arrow-right": FCD5,
  "clipboard-arrow-right-outline": FCD6,
  "clipboard-arrow-up": FC33,
  "clipboard-arrow-up-outline": FC34,
  "clipboard-check": F14C,
  "clipboard-check-outline": F8A7,
  "clipboard-flow": F6C7,
  "clipboard-outline": F14D,
  "clipboard-play": FC35,
  "clipboard-play-outline": FC36,
  "clipboard-plus": F750,
  "clipboard-pulse": F85C,
  "clipboard-pulse-outline": F85D,
  "clipboard-text": F14E,
  "clipboard-text-outline": FA37,
  "clipboard-text-play": FC37,
  "clipboard-text-play-outline": FC38,
  "clippy": F14F,
  "clock": F953,
  "clock-alert": F954,
  "clock-alert-outline": F5CE,
  "clock-digital": FEB4,
  "clock-end": F151,
  "clock-fast": F152,
  "clock-in": F153,
  "clock-out": F154,
  "clock-outline": F150,
  "clock-start": F155,
  "close": F156,
  "close-box": F157,
  "close-box-multiple": FC39,
  "close-box-multiple-outline": FC3A,
  "close-box-outline": F158,
  "close-circle": F159,
  "close-circle-outline": F15A,
  "close-network": F15B,
  "close-network-outline": FC3B,
  "close-octagon": F15C,
  "close-octagon-outline": F15D,
  "close-outline": F6C8,
  "closed-caption": F15E,
  "closed-caption-outline": FD99,
  "cloud": F15F,
  "cloud-alert": F9DF,
  "cloud-braces": F7B4,
  "cloud-check": F160,
  "cloud-circle": F161,
  "cloud-download": F162,
  "cloud-download-outline": FB59,
  "cloud-off-outline": F164,
  "cloud-outline": F163,
  "cloud-print": F165,
  "cloud-print-outline": F166,
  "cloud-question": FA38,
  "cloud-search": F955,
  "cloud-search-outline": F956,
  "cloud-sync": F63F,
  "cloud-tags": F7B5,
  "cloud-upload": F167,
  "cloud-upload-outline": FB5A,
  "clover": F815,
  "code-array": F168,
  "code-braces": F169,
  "code-brackets": F16A,
  "code-equal": F16B,
  "code-greater-than": F16C,
  "code-greater-than-or-equal": F16D,
  "code-less-than": F16E,
  "code-less-than-or-equal": F16F,
  "code-not-equal": F170,
  "code-not-equal-variant": F171,
  "code-parentheses": F172,
  "code-string": F173,
  "code-tags": F174,
  "code-tags-check": F693,
  "codepen": F175,
  "coffee": F176,
  "coffee-off": FFCA,
  "coffee-off-outline": FFCB,
  "coffee-outline": F6C9,
  "coffee-to-go": F177,
  "coffin": FB5B,
  "cogs": F8D5,
  "coin": F178,
  "coins": F694,
  "collage": F640,
  "collapse-all": FAA5,
  "collapse-all-outline": FAA6,
  "color-helper": F179,
  "comma": FE74,
  "comma-box": FE75,
  "comma-box-outline": FE76,
  "comma-circle": FE77,
  "comma-circle-outline": FE78,
  "comment": F17A,
  "comment-account": F17B,
  "comment-account-outline": F17C,
  "comment-alert": F17D,
  "comment-alert-outline": F17E,
  "comment-arrow-left": F9E0,
  "comment-arrow-left-outline": F9E1,
  "comment-arrow-right": F9E2,
  "comment-arrow-right-outline": F9E3,
  "comment-check": F17F,
  "comment-check-outline": F180,
  "comment-eye": FA39,
  "comment-eye-outline": FA3A,
  "comment-multiple": F85E,
  "comment-multiple-outline": F181,
  "comment-outline": F182,
  "comment-plus": F9E4,
  "comment-plus-outline": F183,
  "comment-processing": F184,
  "comment-processing-outline": F185,
  "comment-question": F816,
  "comment-question-outline": F186,
  "comment-remove": F5DE,
  "comment-remove-outline": F187,
  "comment-search": FA3B,
  "comment-search-outline": FA3C,
  "comment-text": F188,
  "comment-text-multiple": F85F,
  "comment-text-multiple-outline": F860,
  "comment-text-outline": F189,
  "compare": F18A,
  "compass": F18B,
  "compass-off": FB5C,
  "compass-off-outline": FB5D,
  "compass-outline": F18C,
  "console": F18D,
  "console-line": F7B6,
  "console-network": F8A8,
  "console-network-outline": FC3C,
  "contact-mail": F18E,
  "contact-mail-outline": FEB5,
  "contact-phone": FEB6,
  "contact-phone-outline": FEB7,
  "contactless-payment": FD46,
  "contacts": F6CA,
  "contain": FA3D,
  "contain-end": FA3E,
  "contain-start": FA3F,
  "content-copy": F18F,
  "content-cut": F190,
  "content-duplicate": F191,
  "content-paste": F192,
  "content-save": F193,
  "content-save-alert": FF5F,
  "content-save-alert-outline": FF60,
  "content-save-all": F194,
  "content-save-all-outline": FF61,
  "content-save-edit": FCD7,
  "content-save-edit-outline": FCD8,
  "content-save-move": FE79,
  "content-save-move-outline": FE7A,
  "content-save-outline": F817,
  "content-save-settings": F61B,
  "content-save-settings-outline": FB13,
  "contrast": F195,
  "contrast-box": F196,
  "contrast-circle": F197,
  "controller-classic": FB5E,
  "controller-classic-outline": FB5F,
  "cookie": F198,
  "copyright": F5E6,
  "cordova": F957,
  "corn": F7B7,
  "counter": F199,
  "cow": F19A,
  "cowboy": FEB8,
  "cpu-32-bit": FEFC,
  "cpu-64-bit": FEFD,
  "crane": F861,
  "creation": F1C9,
  "creative-commons": FD47,
  "credit-card": F19B,
  "credit-card-clock": FEFE,
  "credit-card-clock-outline": FFBC,
  "credit-card-marker": FD9A,
  "credit-card-multiple": F19C,
  "credit-card-off": F5E4,
  "credit-card-plus": F675,
  "credit-card-refund": FAA7,
  "credit-card-scan": F19D,
  "credit-card-settings": F8D6,
  "credit-card-wireless": FD48,
  "cricket": FD49,
  "crop": F19E,
  "crop-free": F19F,
  "crop-landscape": F1A0,
  "crop-portrait": F1A1,
  "crop-rotate": F695,
  "crop-square": F1A2,
  "crosshairs": F1A3,
  "crosshairs-gps": F1A4,
  "crosshairs-off": FF62,
  "crown": F1A5,
  "cryengine": F958,
  "crystal-ball": FB14,
  "cube": F1A6,
  "cube-outline": F1A7,
  "cube-scan": FB60,
  "cube-send": F1A8,
  "cube-unfolded": F1A9,
  "cup": F1AA,
  "cup-off": F5E5,
  "cup-water": F1AB,
  "cupboard": FF63,
  "cupboard-outline": FF64,
  "cupcake": F959,
  "curling": F862,
  "currency-bdt": F863,
  "currency-brl": FB61,
  "currency-btc": F1AC,
  "currency-chf": F7B8,
  "currency-cny": F7B9,
  "currency-eth": F7BA,
  "currency-eur": F1AD,
  "currency-gbp": F1AE,
  "currency-ils": FC3D,
  "currency-inr": F1AF,
  "currency-jpy": F7BB,
  "currency-krw": F7BC,
  "currency-kzt": F864,
  "currency-ngn": F1B0,
  "currency-php": F9E5,
  "currency-rial": FEB9,
  "currency-rub": F1B1,
  "currency-sign": F7BD,
  "currency-try": F1B2,
  "currency-twd": F7BE,
  "currency-usd": F1B3,
  "currency-usd-off": F679,
  "current-ac": F95A,
  "current-dc": F95B,
  "cursor-default": F1B4,
  "cursor-default-click": FCD9,
  "cursor-default-click-outline": FCDA,
  "cursor-default-outline": F1B5,
  "cursor-move": F1B6,
  "cursor-pointer": F1B7,
  "cursor-text": F5E7,
  "database": F1B8,
  "database-check": FAA8,
  "database-edit": FB62,
  "database-export": F95D,
  "database-import": F95C,
  "database-lock": FAA9,
  "database-minus": F1B9,
  "database-plus": F1BA,
  "database-refresh": FCDB,
  "database-remove": FCDC,
  "database-search": F865,
  "database-settings": FCDD,
  "death-star": F8D7,
  "death-star-variant": F8D8,
  "deathly-hallows": FB63,
  "debian": F8D9,
  "debug-step-into": F1BB,
  "debug-step-out": F1BC,
  "debug-step-over": F1BD,
  "decagram": F76B,
  "decagram-outline": F76C,
  "decimal-decrease": F1BE,
  "decimal-increase": F1BF,
  "delete": F1C0,
  "delete-circle": F682,
  "delete-circle-outline": FB64,
  "delete-empty": F6CB,
  "delete-empty-outline": FEBA,
  "delete-forever": F5E8,
  "delete-forever-outline": FB65,
  "delete-outline": F9E6,
  "delete-restore": F818,
  "delete-sweep": F5E9,
  "delete-sweep-outline": FC3E,
  "delete-variant": F1C1,
  "delta": F1C2,
  "desk-lamp": F95E,
  "deskphone": F1C3,
  "desktop-classic": F7BF,
  "desktop-mac": F1C4,
  "desktop-mac-dashboard": F9E7,
  "desktop-tower": F1C5,
  "desktop-tower-monitor": FAAA,
  "details": F1C6,
  "dev-to": FD4A,
  "developer-board": F696,
  "deviantart": F1C7,
  "dialpad": F61C,
  "diameter": FC3F,
  "diameter-outline": FC40,
  "diameter-variant": FC41,
  "diamond": FB66,
  "diamond-outline": FB67,
  "diamond-stone": F1C8,
  "dice-1": F1CA,
  "dice-2": F1CB,
  "dice-3": F1CC,
  "dice-4": F1CD,
  "dice-5": F1CE,
  "dice-6": F1CF,
  "dice-d10": F76E,
  "dice-d12": F866,
  "dice-d20": F5EA,
  "dice-d4": F5EB,
  "dice-d6": F5EC,
  "dice-d8": F5ED,
  "dice-multiple": F76D,
  "dictionary": F61D,
  "dip-switch": F7C0,
  "directions": F1D0,
  "directions-fork": F641,
  "disc": F5EE,
  "disc-alert": F1D1,
  "disc-player": F95F,
  "discord": F66F,
  "dishwasher": FAAB,
  "disqus": F1D2,
  "disqus-outline": F1D3,
  "diving-flippers": FD9B,
  "diving-helmet": FD9C,
  "diving-scuba": FD9D,
  "diving-scuba-flag": FD9E,
  "diving-scuba-tank": FD9F,
  "diving-scuba-tank-multiple": FDA0,
  "diving-snorkel": FDA1,
  "division": F1D4,
  "division-box": F1D5,
  "dlna": FA40,
  "dna": F683,
  "dns": F1D6,
  "dns-outline": FB68,
  "do-not-disturb": F697,
  "do-not-disturb-off": F698,
  "docker": F867,
  "doctor": FA41,
  "dog": FA42,
  "dog-service": FAAC,
  "dog-side": FA43,
  "dolby": F6B2,
  "dolly": FEBB,
  "domain": F1D7,
  "domain-off": FD4B,
  "donkey": F7C1,
  "door": F819,
  "door-closed": F81A,
  "door-open": F81B,
  "doorbell-video": F868,
  "dot-net": FAAD,
  "dots-horizontal": F1D8,
  "dots-horizontal-circle": F7C2,
  "dots-horizontal-circle-outline": FB69,
  "dots-vertical": F1D9,
  "dots-vertical-circle": F7C3,
  "dots-vertical-circle-outline": FB6A,
  "douban": F699,
  "download": F1DA,
  "download-multiple": F9E8,
  "download-network": F6F3,
  "download-network-outline": FC42,
  "download-outline": FB6B,
  "drag": F1DB,
  "drag-horizontal": F1DC,
  "drag-variant": FB6C,
  "drag-vertical": F1DD,
  "drama-masks": FCDE,
  "draw": FF66,
  "drawing": F1DE,
  "drawing-box": F1DF,
  "dresser": FF67,
  "dresser-outline": FF68,
  "dribbble": F1E0,
  "dribbble-box": F1E1,
  "drone": F1E2,
  "dropbox": F1E3,
  "drupal": F1E4,
  "duck": F1E5,
  "dumbbell": F1E6,
  "dump-truck": FC43,
  "ear-hearing": F7C4,
  "ear-hearing-off": FA44,
  "earth": F1E7,
  "earth-box": F6CC,
  "earth-box-off": F6CD,
  "earth-off": F1E8,
  "edge": F1E9,
  "egg": FAAE,
  "egg-easter": FAAF,
  "eight-track": F9E9,
  "eject": F1EA,
  "eject-outline": FB6D,
  "electric-switch": FEBC,
  "elephant": F7C5,
  "elevation-decline": F1EB,
  "elevation-rise": F1EC,
  "elevator": F1ED,
  "ellipse": FEBD,
  "ellipse-outline": FEBE,
  "email": F1EE,
  "email-alert": F6CE,
  "email-box": FCDF,
  "email-check": FAB0,
  "email-check-outline": FAB1,
  "email-edit": FF00,
  "email-edit-outline": FF01,
  "email-lock": F1F1,
  "email-mark-as-unread": FB6E,
  "email-minus": FF02,
  "email-minus-outline": FF03,
  "email-multiple": FF04,
  "email-multiple-outline": FF05,
  "email-open": F1EF,
  "email-open-multiple": FF06,
  "email-open-multiple-outline": FF07,
  "email-open-outline": F5EF,
  "email-outline": F1F0,
  "email-plus": F9EA,
  "email-plus-outline": F9EB,
  "email-search": F960,
  "email-search-outline": F961,
  "email-variant": F5F0,
  "ember": FB15,
  "emby": F6B3,
  "emoticon": FC44,
  "emoticon-angry": FC45,
  "emoticon-angry-outline": FC46,
  "emoticon-cool": FC47,
  "emoticon-cool-outline": F1F3,
  "emoticon-cry": FC48,
  "emoticon-cry-outline": FC49,
  "emoticon-dead": FC4A,
  "emoticon-dead-outline": F69A,
  "emoticon-devil": FC4B,
  "emoticon-devil-outline": F1F4,
  "emoticon-excited": FC4C,
  "emoticon-excited-outline": F69B,
  "emoticon-frown": FF69,
  "emoticon-frown-outline": FF6A,
  "emoticon-happy": FC4D,
  "emoticon-happy-outline": F1F5,
  "emoticon-kiss": FC4E,
  "emoticon-kiss-outline": FC4F,
  "emoticon-neutral": FC50,
  "emoticon-neutral-outline": F1F6,
  "emoticon-outline": F1F2,
  "emoticon-poop": F1F7,
  "emoticon-poop-outline": FC51,
  "emoticon-sad": FC52,
  "emoticon-sad-outline": F1F8,
  "emoticon-tongue": F1F9,
  "emoticon-tongue-outline": FC53,
  "emoticon-wink": FC54,
  "emoticon-wink-outline": FC55,
  "engine": F1FA,
  "engine-off": FA45,
  "engine-off-outline": FA46,
  "engine-outline": F1FB,
  "equal": F1FC,
  "equal-box": F1FD,
  "equalizer": FEBF,
  "equalizer-outline": FEC0,
  "eraser": F1FE,
  "eraser-variant": F642,
  "escalator": F1FF,
  "eslint": FC56,
  "et": FAB2,
  "ethereum": F869,
  "ethernet": F200,
  "ethernet-cable": F201,
  "ethernet-cable-off": F202,
  "etsy": F203,
  "ev-station": F5F1,
  "eventbrite": F7C6,
  "evernote": F204,
  "exclamation": F205,
  "exit-run": FA47,
  "exit-to-app": F206,
  "expand-all": FAB3,
  "expand-all-outline": FAB4,
  "exponent": F962,
  "exponent-box": F963,
  "export": F207,
  "export-variant": FB6F,
  "eye": F208,
  "eye-check": FCE0,
  "eye-check-outline": FCE1,
  "eye-circle": FB70,
  "eye-circle-outline": FB71,
  "eye-off": F209,
  "eye-off-outline": F6D0,
  "eye-outline": F6CF,
  "eye-plus": F86A,
  "eye-plus-outline": F86B,
  "eye-settings": F86C,
  "eye-settings-outline": F86D,
  "eyedropper": F20A,
  "eyedropper-variant": F20B,
  "face": F643,
  "face-agent": FD4C,
  "face-outline": FB72,
  "face-profile": F644,
  "face-recognition": FC57,
  "facebook": F20C,
  "facebook-box": F20D,
  "facebook-messenger": F20E,
  "facebook-workplace": FB16,
  "factory": F20F,
  "fan": F210,
  "fan-off": F81C,
  "fast-forward": F211,
  "fast-forward-10": FD4D,
  "fast-forward-30": FCE2,
  "fast-forward-outline": F6D1,
  "fax": F212,
  "feather": F6D2,
  "feature-search": FA48,
  "feature-search-outline": FA49,
  "fedora": F8DA,
  "ferris-wheel": FEC1,
  "ferry": F213,
  "file": F214,
  "file-account": F73A,
  "file-alert": FA4A,
  "file-alert-outline": FA4B,
  "file-cabinet": FAB5,
  "file-cad": FF08,
  "file-cad-box": FF09,
  "file-cancel": FDA2,
  "file-cancel-outline": FDA3,
  "file-chart": F215,
  "file-check": F216,
  "file-check-outline": FE7B,
  "file-cloud": F217,
  "file-compare": F8A9,
  "file-delimited": F218,
  "file-delimited-outline": FEC2,
  "file-document": F219,
  "file-document-box": F21A,
  "file-document-box-check": FEC3,
  "file-document-box-check-outline": FEC4,
  "file-document-box-minus": FEC5,
  "file-document-box-minus-outline": FEC6,
  "file-document-box-multiple": FAB6,
  "file-document-box-multiple-outline": FAB7,
  "file-document-box-outline": F9EC,
  "file-document-box-plus": FEC7,
  "file-document-box-plus-outline": FEC8,
  "file-document-box-remove": FEC9,
  "file-document-box-remove-outline": FECA,
  "file-document-box-search": FECB,
  "file-document-box-search-outline": FECC,
  "file-document-edit": FDA4,
  "file-document-edit-outline": FDA5,
  "file-document-outline": F9ED,
  "file-download": F964,
  "file-download-outline": F965,
  "file-excel": F21B,
  "file-excel-box": F21C,
  "file-export": F21D,
  "file-eye": FDA6,
  "file-eye-outline": FDA7,
  "file-find": F21E,
  "file-find-outline": FB73,
  "file-hidden": F613,
  "file-image": F21F,
  "file-image-outline": FECD,
  "file-import": F220,
  "file-lock": F221,
  "file-move": FAB8,
  "file-multiple": F222,
  "file-music": F223,
  "file-music-outline": FE7C,
  "file-outline": F224,
  "file-pdf": F225,
  "file-pdf-box": F226,
  "file-pdf-outline": FE7D,
  "file-percent": F81D,
  "file-plus": F751,
  "file-plus-outline": FF0A,
  "file-powerpoint": F227,
  "file-powerpoint-box": F228,
  "file-presentation-box": F229,
  "file-question": F86E,
  "file-remove": FB74,
  "file-replace": FB17,
  "file-replace-outline": FB18,
  "file-restore": F670,
  "file-search": FC58,
  "file-search-outline": FC59,
  "file-send": F22A,
  "file-table": FC5A,
  "file-table-outline": FC5B,
  "file-tree": F645,
  "file-undo": F8DB,
  "file-upload": FA4C,
  "file-upload-outline": FA4D,
  "file-video": F22B,
  "file-video-outline": FE10,
  "file-word": F22C,
  "file-word-box": F22D,
  "file-xml": F22E,
  "film": F22F,
  "filmstrip": F230,
  "filmstrip-off": F231,
  "filter": F232,
  "filter-minus": FF0B,
  "filter-minus-outline": FF0C,
  "filter-outline": F233,
  "filter-plus": FF0D,
  "filter-plus-outline": FF0E,
  "filter-remove": F234,
  "filter-remove-outline": F235,
  "filter-variant": F236,
  "finance": F81E,
  "find-replace": F6D3,
  "fingerprint": F237,
  "fingerprint-off": FECE,
  "fire": F238,
  "fire-extinguisher": FF0F,
  "fire-truck": F8AA,
  "firebase": F966,
  "firefox": F239,
  "fireplace": FE11,
  "fireplace-off": FE12,
  "firework": FE13,
  "fish": F23A,
  "fishbowl": FF10,
  "fishbowl-outline": FF11,
  "fit-to-page": FF12,
  "fit-to-page-outline": FF13,
  "flag": F23B,
  "flag-checkered": F23C,
  "flag-minus": FB75,
  "flag-outline": F23D,
  "flag-plus": FB76,
  "flag-remove": FB77,
  "flag-triangle": F23F,
  "flag-variant": F240,
  "flag-variant-outline": F23E,
  "flare": FD4E,
  "flash": F241,
  "flash-alert": FF14,
  "flash-alert-outline": FF15,
  "flash-auto": F242,
  "flash-circle": F81F,
  "flash-off": F243,
  "flash-outline": F6D4,
  "flash-red-eye": F67A,
  "flashlight": F244,
  "flashlight-off": F245,
  "flask": F093,
  "flask-empty": F094,
  "flask-empty-outline": F095,
  "flask-outline": F096,
  "flattr": F246,
  "flickr": FCE3,
  "flip-to-back": F247,
  "flip-to-front": F248,
  "floor-lamp": F8DC,
  "floor-plan": F820,
  "floppy": F249,
  "floppy-variant": F9EE,
  "flower": F24A,
  "flower-outline": F9EF,
  "flower-poppy": FCE4,
  "flower-tulip": F9F0,
  "flower-tulip-outline": F9F1,
  "focus-auto": FF6B,
  "focus-field": FF6C,
  "focus-field-horizontal": FF6D,
  "focus-field-vertical": FF6E,
  "folder": F24B,
  "folder-account": F24C,
  "folder-account-outline": FB78,
  "folder-alert": FDA8,
  "folder-alert-outline": FDA9,
  "folder-clock": FAB9,
  "folder-clock-outline": FABA,
  "folder-download": F24D,
  "folder-edit": F8DD,
  "folder-edit-outline": FDAA,
  "folder-google-drive": F24E,
  "folder-image": F24F,
  "folder-key": F8AB,
  "folder-key-network": F8AC,
  "folder-key-network-outline": FC5C,
  "folder-lock": F250,
  "folder-lock-open": F251,
  "folder-move": F252,
  "folder-multiple": F253,
  "folder-multiple-image": F254,
  "folder-multiple-outline": F255,
  "folder-network": F86F,
  "folder-network-outline": FC5D,
  "folder-open": F76F,
  "folder-open-outline": FDAB,
  "folder-outline": F256,
  "folder-plus": F257,
  "folder-plus-outline": FB79,
  "folder-pound": FCE5,
  "folder-pound-outline": FCE6,
  "folder-remove": F258,
  "folder-remove-outline": FB7A,
  "folder-search": F967,
  "folder-search-outline": F968,
  "folder-star": F69C,
  "folder-star-outline": FB7B,
  "folder-sync": FCE7,
  "folder-sync-outline": FCE8,
  "folder-text": FC5E,
  "folder-text-outline": FC5F,
  "folder-upload": F259,
  "font-awesome": F03A,
  "food": F25A,
  "food-apple": F25B,
  "food-apple-outline": FC60,
  "food-croissant": F7C7,
  "food-fork-drink": F5F2,
  "food-off": F5F3,
  "food-variant": F25C,
  "foot-print": FF6F,
  "football": F25D,
  "football-australian": F25E,
  "football-helmet": F25F,
  "forklift": F7C8,
  "format-align-bottom": F752,
  "format-align-center": F260,
  "format-align-justify": F261,
  "format-align-left": F262,
  "format-align-middle": F753,
  "format-align-right": F263,
  "format-align-top": F754,
  "format-annotation-minus": FABB,
  "format-annotation-plus": F646,
  "format-bold": F264,
  "format-clear": F265,
  "format-color-fill": F266,
  "format-color-highlight": FE14,
  "format-color-text": F69D,
  "format-columns": F8DE,
  "format-float-center": F267,
  "format-float-left": F268,
  "format-float-none": F269,
  "format-float-right": F26A,
  "format-font": F6D5,
  "format-font-size-decrease": F9F2,
  "format-font-size-increase": F9F3,
  "format-header-1": F26B,
  "format-header-2": F26C,
  "format-header-3": F26D,
  "format-header-4": F26E,
  "format-header-5": F26F,
  "format-header-6": F270,
  "format-header-decrease": F271,
  "format-header-equal": F272,
  "format-header-increase": F273,
  "format-header-pound": F274,
  "format-horizontal-align-center": F61E,
  "format-horizontal-align-left": F61F,
  "format-horizontal-align-right": F620,
  "format-indent-decrease": F275,
  "format-indent-increase": F276,
  "format-italic": F277,
  "format-letter-case": FB19,
  "format-letter-case-lower": FB1A,
  "format-letter-case-upper": FB1B,
  "format-line-spacing": F278,
  "format-line-style": F5C8,
  "format-line-weight": F5C9,
  "format-list-bulleted": F279,
  "format-list-bulleted-square": FDAC,
  "format-list-bulleted-type": F27A,
  "format-list-checkbox": F969,
  "format-list-checks": F755,
  "format-list-numbered": F27B,
  "format-list-numbered-rtl": FCE9,
  "format-list-triangle": FECF,
  "format-overline": FED0,
  "format-page-break": F6D6,
  "format-paint": F27C,
  "format-paragraph": F27D,
  "format-pilcrow": F6D7,
  "format-quote-close": F27E,
  "format-quote-open": F756,
  "format-rotate-90": F6A9,
  "format-section": F69E,
  "format-size": F27F,
  "format-strikethrough": F280,
  "format-strikethrough-variant": F281,
  "format-subscript": F282,
  "format-superscript": F283,
  "format-text": F284,
  "format-text-rotation-down": FD4F,
  "format-text-rotation-none": FD50,
  "format-text-variant": FE15,
  "format-text-wrapping-clip": FCEA,
  "format-text-wrapping-overflow": FCEB,
  "format-text-wrapping-wrap": FCEC,
  "format-textbox": FCED,
  "format-textdirection-l-to-r": F285,
  "format-textdirection-r-to-l": F286,
  "format-title": F5F4,
  "format-underline": F287,
  "format-vertical-align-bottom": F621,
  "format-vertical-align-center": F622,
  "format-vertical-align-top": F623,
  "format-wrap-inline": F288,
  "format-wrap-square": F289,
  "format-wrap-tight": F28A,
  "format-wrap-top-bottom": F28B,
  "forum": F28C,
  "forum-outline": F821,
  "forward": F28D,
  "forwardburger": FD51,
  "fountain": F96A,
  "fountain-pen": FCEE,
  "fountain-pen-tip": FCEF,
  "foursquare": F28E,
  "freebsd": F8DF,
  "frequently-asked-questions": FED1,
  "fridge": F290,
  "fridge-bottom": F292,
  "fridge-outline": F28F,
  "fridge-top": F291,
  "fuel": F7C9,
  "fullscreen": F293,
  "fullscreen-exit": F294,
  "function": F295,
  "function-variant": F870,
  "fuse": FC61,
  "fuse-blade": FC62,
  "gamepad": F296,
  "gamepad-circle": FE16,
  "gamepad-circle-down": FE17,
  "gamepad-circle-left": FE18,
  "gamepad-circle-outline": FE19,
  "gamepad-circle-right": FE1A,
  "gamepad-circle-up": FE1B,
  "gamepad-down": FE1C,
  "gamepad-left": FE1D,
  "gamepad-right": FE1E,
  "gamepad-round": FE1F,
  "gamepad-round-down": FE7E,
  "gamepad-round-left": FE7F,
  "gamepad-round-outline": FE80,
  "gamepad-round-right": FE81,
  "gamepad-round-up": FE82,
  "gamepad-square": FED2,
  "gamepad-square-outline": FED3,
  "gamepad-up": FE83,
  "gamepad-variant": F297,
  "gamepad-variant-outline": FED4,
  "gantry-crane": FDAD,
  "garage": F6D8,
  "garage-alert": F871,
  "garage-open": F6D9,
  "gas-cylinder": F647,
  "gas-station": F298,
  "gas-station-outline": FED5,
  "gate": F299,
  "gate-and": F8E0,
  "gate-nand": F8E1,
  "gate-nor": F8E2,
  "gate-not": F8E3,
  "gate-or": F8E4,
  "gate-xnor": F8E5,
  "gate-xor": F8E6,
  "gatsby": FE84,
  "gauge": F29A,
  "gauge-empty": F872,
  "gauge-full": F873,
  "gauge-low": F874,
  "gavel": F29B,
  "gender-female": F29C,
  "gender-male": F29D,
  "gender-male-female": F29E,
  "gender-transgender": F29F,
  "gentoo": F8E7,
  "gesture": F7CA,
  "gesture-double-tap": F73B,
  "gesture-pinch": FABC,
  "gesture-spread": FABD,
  "gesture-swipe": FD52,
  "gesture-swipe-down": F73C,
  "gesture-swipe-horizontal": FABE,
  "gesture-swipe-left": F73D,
  "gesture-swipe-right": F73E,
  "gesture-swipe-up": F73F,
  "gesture-swipe-vertical": FABF,
  "gesture-tap": F740,
  "gesture-tap-hold": FD53,
  "gesture-two-double-tap": F741,
  "gesture-two-tap": F742,
  "ghost": F2A0,
  "ghost-off": F9F4,
  "gif": FD54,
  "gift": FE85,
  "gift-outline": F2A1,
  "git": F2A2,
  "github-box": F2A3,
  "github-circle": F2A4,
  "github-face": F6DA,
  "gitlab": FB7C,
  "glass-cocktail": F356,
  "glass-flute": F2A5,
  "glass-mug": F2A6,
  "glass-stange": F2A7,
  "glass-tulip": F2A8,
  "glass-wine": F875,
  "glassdoor": F2A9,
  "glasses": F2AA,
  "globe-model": F8E8,
  "gmail": F2AB,
  "gnome": F2AC,
  "go-kart": FD55,
  "go-kart-track": FD56,
  "gog": FB7D,
  "golf": F822,
  "gondola": F685,
  "goodreads": FD57,
  "google": F2AD,
  "google-adwords": FC63,
  "google-allo": F801,
  "google-analytics": F7CB,
  "google-assistant": F7CC,
  "google-cardboard": F2AE,
  "google-chrome": F2AF,
  "google-circles": F2B0,
  "google-circles-communities": F2B1,
  "google-circles-extended": F2B2,
  "google-circles-group": F2B3,
  "google-classroom": F2C0,
  "google-controller": F2B4,
  "google-controller-off": F2B5,
  "google-drive": F2B6,
  "google-earth": F2B7,
  "google-fit": F96B,
  "google-glass": F2B8,
  "google-hangouts": F2C9,
  "google-home": F823,
  "google-keep": F6DB,
  "google-lens": F9F5,
  "google-maps": F5F5,
  "google-nearby": F2B9,
  "google-pages": F2BA,
  "google-photos": F6DC,
  "google-physical-web": F2BB,
  "google-play": F2BC,
  "google-plus": F2BD,
  "google-plus-box": F2BE,
  "google-podcast": FED6,
  "google-spreadsheet": F9F6,
  "google-street-view": FC64,
  "google-translate": F2BF,
  "gpu": F8AD,
  "gradient": F69F,
  "grain": FD58,
  "graphql": F876,
  "grave-stone": FB7E,
  "grease-pencil": F648,
  "greater-than": F96C,
  "greater-than-or-equal": F96D,
  "grid": F2C1,
  "grid-large": F757,
  "grid-off": F2C2,
  "grill": FE86,
  "group": F2C3,
  "guitar-acoustic": F770,
  "guitar-electric": F2C4,
  "guitar-pick": F2C5,
  "guitar-pick-outline": F2C6,
  "guy-fawkes-mask": F824,
  "hackernews": F624,
  "hail": FAC0,
  "halloween": FB7F,
  "hamburger": F684,
  "hammer": F8E9,
  "hand": FA4E,
  "hand-left": FE87,
  "hand-okay": FA4F,
  "hand-peace": FA50,
  "hand-peace-variant": FA51,
  "hand-pointing-down": FA52,
  "hand-pointing-left": FA53,
  "hand-pointing-right": F2C7,
  "hand-pointing-up": FA54,
  "hand-right": FE88,
  "hand-saw": FE89,
  "handball": FF70,
  "hanger": F2C8,
  "hard-hat": F96E,
  "harddisk": F2CA,
  "hat-fedora": FB80,
  "hazard-lights": FC65,
  "hdr": FD59,
  "hdr-off": FD5A,
  "headphones": F2CB,
  "headphones-bluetooth": F96F,
  "headphones-box": F2CC,
  "headphones-off": F7CD,
  "headphones-settings": F2CD,
  "headset": F2CE,
  "headset-dock": F2CF,
  "headset-off": F2D0,
  "heart": F2D1,
  "heart-box": F2D2,
  "heart-box-outline": F2D3,
  "heart-broken": F2D4,
  "heart-broken-outline": FCF0,
  "heart-circle": F970,
  "heart-circle-outline": F971,
  "heart-flash": FF16,
  "heart-half": F6DE,
  "heart-half-full": F6DD,
  "heart-half-outline": F6DF,
  "heart-multiple": FA55,
  "heart-multiple-outline": FA56,
  "heart-off": F758,
  "heart-outline": F2D5,
  "heart-pulse": F5F6,
  "helicopter": FAC1,
  "help": F2D6,
  "help-box": F78A,
  "help-circle": F2D7,
  "help-circle-outline": F625,
  "help-network": F6F4,
  "help-network-outline": FC66,
  "help-rhombus": FB81,
  "help-rhombus-outline": FB82,
  "hexagon": F2D8,
  "hexagon-multiple": F6E0,
  "hexagon-outline": F2D9,
  "hexagon-slice-1": FAC2,
  "hexagon-slice-2": FAC3,
  "hexagon-slice-3": FAC4,
  "hexagon-slice-4": FAC5,
  "hexagon-slice-5": FAC6,
  "hexagon-slice-6": FAC7,
  "hexagram": FAC8,
  "hexagram-outline": FAC9,
  "high-definition": F7CE,
  "high-definition-box": F877,
  "highway": F5F7,
  "hiking": FD5B,
  "hinduism": F972,
  "history": F2DA,
  "hockey-puck": F878,
  "hockey-sticks": F879,
  "hololens": F2DB,
  "home": F2DC,
  "home-account": F825,
  "home-alert": F87A,
  "home-analytics": FED7,
  "home-assistant": F7CF,
  "home-automation": F7D0,
  "home-circle": F7D1,
  "home-city": FCF1,
  "home-city-outline": FCF2,
  "home-currency-usd": F8AE,
  "home-export-outline": FFB8,
  "home-flood": FF17,
  "home-floor-0": FDAE,
  "home-floor-1": FD5C,
  "home-floor-2": FD5D,
  "home-floor-3": FD5E,
  "home-floor-a": FD5F,
  "home-floor-b": FD60,
  "home-floor-g": FD61,
  "home-floor-l": FD62,
  "home-floor-negative-1": FDAF,
  "home-group": FDB0,
  "home-heart": F826,
  "home-import-outline": FFB9,
  "home-lock": F8EA,
  "home-lock-open": F8EB,
  "home-map-marker": F5F8,
  "home-minus": F973,
  "home-modern": F2DD,
  "home-outline": F6A0,
  "home-plus": F974,
  "home-thermometer": FF71,
  "home-thermometer-outline": FF72,
  "home-variant": F2DE,
  "home-variant-outline": FB83,
  "hook": F6E1,
  "hook-off": F6E2,
  "hops": F2DF,
  "horseshoe": FA57,
  "hospital": F2E0,
  "hospital-building": F2E1,
  "hospital-marker": F2E2,
  "hot-tub": F827,
  "hotel": F2E3,
  "houzz": F2E4,
  "houzz-box": F2E5,
  "hubspot": FCF3,
  "hulu": F828,
  "human": F2E6,
  "human-child": F2E7,
  "human-female": F649,
  "human-female-boy": FA58,
  "human-female-female": FA59,
  "human-female-girl": FA5A,
  "human-greeting": F64A,
  "human-handsdown": F64B,
  "human-handsup": F64C,
  "human-male": F64D,
  "human-male-boy": FA5B,
  "human-male-female": F2E8,
  "human-male-girl": FA5C,
  "human-male-height": FF18,
  "human-male-height-variant": FF19,
  "human-male-male": FA5D,
  "human-pregnant": F5CF,
  "humble-bundle": F743,
  "ice-cream": F829,
  "ice-pop": FF1A,
  "identifier": FF1B,
  "iframe": FC67,
  "iframe-outline": FC68,
  "image": F2E9,
  "image-album": F2EA,
  "image-area": F2EB,
  "image-area-close": F2EC,
  "image-broken": F2ED,
  "image-broken-variant": F2EE,
  "image-filter": F2EF,
  "image-filter-black-white": F2F0,
  "image-filter-center-focus": F2F1,
  "image-filter-center-focus-strong": FF1C,
  "image-filter-center-focus-strong-outline": FF1D,
  "image-filter-center-focus-weak": F2F2,
  "image-filter-drama": F2F3,
  "image-filter-frames": F2F4,
  "image-filter-hdr": F2F5,
  "image-filter-none": F2F6,
  "image-filter-tilt-shift": F2F7,
  "image-filter-vintage": F2F8,
  "image-frame": FE8A,
  "image-move": F9F7,
  "image-multiple": F2F9,
  "image-off": F82A,
  "image-outline": F975,
  "image-plus": F87B,
  "image-search": F976,
  "image-search-outline": F977,
  "image-size-select-actual": FC69,
  "image-size-select-large": FC6A,
  "image-size-select-small": FC6B,
  "import": F2FA,
  "inbox": F686,
  "inbox-arrow-down": F2FB,
  "inbox-arrow-up": F3D1,
  "inbox-multiple": F8AF,
  "inbox-multiple-outline": FB84,
  "incognito": F5F9,
  "infinity": F6E3,
  "information": F2FC,
  "information-outline": F2FD,
  "information-variant": F64E,
  "instagram": F2FE,
  "instapaper": F2FF,
  "internet-explorer": F300,
  "invert-colors": F301,
  "invert-colors-off": FE8B,
  "ip": FA5E,
  "ip-network": FA5F,
  "ip-network-outline": FC6C,
  "ipod": FC6D,
  "islam": F978,
  "itunes": F676,
  "jabber": FDB1,
  "jeepney": F302,
  "jellyfish": FF1E,
  "jellyfish-outline": FF1F,
  "jira": F303,
  "jquery": F87C,
  "jsfiddle": F304,
  "json": F626,
  "judaism": F979,
  "kabaddi": FD63,
  "karate": F82B,
  "keg": F305,
  "kettle": F5FA,
  "kettle-outline": FF73,
  "key": F306,
  "key-change": F307,
  "key-minus": F308,
  "key-outline": FDB2,
  "key-plus": F309,
  "key-remove": F30A,
  "key-variant": F30B,
  "keyboard": F30C,
  "keyboard-backspace": F30D,
  "keyboard-caps": F30E,
  "keyboard-close": F30F,
  "keyboard-off": F310,
  "keyboard-off-outline": FE8C,
  "keyboard-outline": F97A,
  "keyboard-return": F311,
  "keyboard-settings": F9F8,
  "keyboard-settings-outline": F9F9,
  "keyboard-tab": F312,
  "keyboard-variant": F313,
  "kickstarter": F744,
  "knife": F9FA,
  "knife-military": F9FB,
  "kodi": F314,
  "label": F315,
  "label-off": FACA,
  "label-off-outline": FACB,
  "label-outline": F316,
  "label-variant": FACC,
  "label-variant-outline": FACD,
  "ladybug": F82C,
  "lambda": F627,
  "lamp": F6B4,
  "lan": F317,
  "lan-connect": F318,
  "lan-disconnect": F319,
  "lan-pending": F31A,
  "language-c": F671,
  "language-cpp": F672,
  "language-csharp": F31B,
  "language-css3": F31C,
  "language-go": F7D2,
  "language-haskell": FC6E,
  "language-html5": F31D,
  "language-java": FB1C,
  "language-javascript": F31E,
  "language-lua": F8B0,
  "language-php": F31F,
  "language-python": F320,
  "language-python-text": F321,
  "language-r": F7D3,
  "language-ruby-on-rails": FACE,
  "language-swift": F6E4,
  "language-typescript": F6E5,
  "laptop": F322,
  "laptop-chromebook": F323,
  "laptop-mac": F324,
  "laptop-off": F6E6,
  "laptop-windows": F325,
  "laravel": FACF,
  "lasso": FF20,
  "lastfm": F326,
  "lastpass": F446,
  "latitude": FF74,
  "launch": F327,
  "lava-lamp": F7D4,
  "layers": F328,
  "layers-minus": FE8D,
  "layers-off": F329,
  "layers-off-outline": F9FC,
  "layers-outline": F9FD,
  "layers-plus": FE30,
  "layers-remove": FE31,
  "layers-triple": FF75,
  "layers-triple-outline": FF76,
  "lead-pencil": F64F,
  "leaf": F32A,
  "leaf-maple": FC6F,
  "leak": FDB3,
  "leak-off": FDB4,
  "led-off": F32B,
  "led-on": F32C,
  "led-outline": F32D,
  "led-strip": F7D5,
  "led-variant-off": F32E,
  "led-variant-on": F32F,
  "led-variant-outline": F330,
  "less-than": F97B,
  "less-than-or-equal": F97C,
  "library": F331,
  "library-books": F332,
  "library-movie": FCF4,
  "library-music": F333,
  "library-music-outline": FF21,
  "library-plus": F334,
  "library-shelves": FB85,
  "library-video": FCF5,
  "lifebuoy": F87D,
  "light-switch": F97D,
  "lightbulb": F335,
  "lightbulb-off": FE32,
  "lightbulb-off-outline": FE33,
  "lightbulb-on": F6E7,
  "lightbulb-on-outline": F6E8,
  "lightbulb-outline": F336,
  "lighthouse": F9FE,
  "lighthouse-on": F9FF,
  "link": F337,
  "link-box": FCF6,
  "link-box-outline": FCF7,
  "link-box-variant": FCF8,
  "link-box-variant-outline": FCF9,
  "link-off": F338,
  "link-plus": FC70,
  "link-variant": F339,
  "link-variant-off": F33A,
  "linkedin": F33B,
  "linkedin-box": F33C,
  "linux": F33D,
  "linux-mint": F8EC,
  "litecoin": FA60,
  "loading": F771,
  "lock": F33E,
  "lock-alert": F8ED,
  "lock-clock": F97E,
  "lock-open": F33F,
  "lock-open-outline": F340,
  "lock-outline": F341,
  "lock-pattern": F6E9,
  "lock-plus": F5FB,
  "lock-question": F8EE,
  "lock-reset": F772,
  "lock-smart": F8B1,
  "locker": F7D6,
  "locker-multiple": F7D7,
  "login": F342,
  "login-variant": F5FC,
  "logout": F343,
  "logout-variant": F5FD,
  "longitude": FF77,
  "looks": F344,
  "loop": F6EA,
  "loupe": F345,
  "lumx": F346,
  "lyft": FB1D,
  "magnet": F347,
  "magnet-on": F348,
  "magnify": F349,
  "magnify-close": F97F,
  "magnify-minus": F34A,
  "magnify-minus-cursor": FA61,
  "magnify-minus-outline": F6EB,
  "magnify-plus": F34B,
  "magnify-plus-cursor": FA62,
  "magnify-plus-outline": F6EC,
  "mail": FED8,
  "mail-ru": F34C,
  "mailbox": F6ED,
  "mailbox-open": FD64,
  "mailbox-open-outline": FD65,
  "mailbox-open-up": FD66,
  "mailbox-open-up-outline": FD67,
  "mailbox-outline": FD68,
  "mailbox-up": FD69,
  "mailbox-up-outline": FD6A,
  "map": F34D,
  "map-check": FED9,
  "map-check-outline": FEDA,
  "map-clock": FCFA,
  "map-clock-outline": FCFB,
  "map-legend": FA00,
  "map-marker": F34E,
  "map-marker-alert": FF22,
  "map-marker-alert-outline": FF23,
  "map-marker-check": FC71,
  "map-marker-circle": F34F,
  "map-marker-distance": F8EF,
  "map-marker-minus": F650,
  "map-marker-multiple": F350,
  "map-marker-off": F351,
  "map-marker-outline": F7D8,
  "map-marker-path": FCFC,
  "map-marker-plus": F651,
  "map-marker-question": FF24,
  "map-marker-question-outline": FF25,
  "map-marker-radius": F352,
  "map-marker-remove": FF26,
  "map-marker-remove-variant": FF27,
  "map-minus": F980,
  "map-outline": F981,
  "map-plus": F982,
  "map-search": F983,
  "map-search-outline": F984,
  "mapbox": FB86,
  "margin": F353,
  "markdown": F354,
  "markdown-outline": FF78,
  "marker": F652,
  "marker-cancel": FDB5,
  "marker-check": F355,
  "mastodon": FAD0,
  "mastodon-variant": FAD1,
  "material-design": F985,
  "material-ui": F357,
  "math-compass": F358,
  "math-cos": FC72,
  "math-sin": FC73,
  "math-tan": FC74,
  "matrix": F628,
  "maxcdn": F359,
  "medal": F986,
  "medical-bag": F6EE,
  "medium": F35A,
  "meetup": FAD2,
  "memory": F35B,
  "menu": F35C,
  "menu-down": F35D,
  "menu-down-outline": F6B5,
  "menu-left": F35E,
  "menu-left-outline": FA01,
  "menu-open": FB87,
  "menu-right": F35F,
  "menu-right-outline": FA02,
  "menu-swap": FA63,
  "menu-swap-outline": FA64,
  "menu-up": F360,
  "menu-up-outline": F6B6,
  "merge": FF79,
  "message": F361,
  "message-alert": F362,
  "message-alert-outline": FA03,
  "message-bulleted": F6A1,
  "message-bulleted-off": F6A2,
  "message-draw": F363,
  "message-image": F364,
  "message-outline": F365,
  "message-plus": F653,
  "message-processing": F366,
  "message-reply": F367,
  "message-reply-text": F368,
  "message-settings": F6EF,
  "message-settings-variant": F6F0,
  "message-text": F369,
  "message-text-outline": F36A,
  "message-video": F36B,
  "meteor": F629,
  "metronome": F7D9,
  "metronome-tick": F7DA,
  "micro-sd": F7DB,
  "microphone": F36C,
  "microphone-minus": F8B2,
  "microphone-off": F36D,
  "microphone-outline": F36E,
  "microphone-plus": F8B3,
  "microphone-settings": F36F,
  "microphone-variant": F370,
  "microphone-variant-off": F371,
  "microscope": F654,
  "microsoft": F372,
  "microsoft-dynamics": F987,
  "microwave": FC75,
  "middleware": FF7A,
  "middleware-outline": FF7B,
  "midi": F8F0,
  "midi-port": F8F1,
  "mine": FDB6,
  "minecraft": F373,
  "mini-sd": FA04,
  "minidisc": FA05,
  "minus": F374,
  "minus-box": F375,
  "minus-box-outline": F6F1,
  "minus-circle": F376,
  "minus-circle-outline": F377,
  "minus-network": F378,
  "minus-network-outline": FC76,
  "mixcloud": F62A,
  "mixed-martial-arts": FD6B,
  "mixed-reality": F87E,
  "mixer": F7DC,
  "molecule": FB88,
  "monitor": F379,
  "monitor-cellphone": F988,
  "monitor-cellphone-star": F989,
  "monitor-dashboard": FA06,
  "monitor-lock": FDB7,
  "monitor-multiple": F37A,
  "monitor-off": FD6C,
  "monitor-screenshot": FE34,
  "monitor-speaker": FF7C,
  "monitor-speaker-off": FF7D,
  "monitor-star": FDB8,
  "moon-first-quarter": FF7E,
  "moon-full": FF7F,
  "moon-last-quarter": FF80,
  "moon-new": FF81,
  "moon-waning-crescent": FF82,
  "moon-waning-gibbous": FF83,
  "moon-waxing-crescent": FF84,
  "moon-waxing-gibbous": FF85,
  "more": F37B,
  "mother-nurse": FCFD,
  "motion-sensor": FD6D,
  "motorbike": F37C,
  "mouse": F37D,
  "mouse-bluetooth": F98A,
  "mouse-off": F37E,
  "mouse-variant": F37F,
  "mouse-variant-off": F380,
  "move-resize": F655,
  "move-resize-variant": F656,
  "movie": F381,
  "movie-outline": FDB9,
  "movie-roll": F7DD,
  "muffin": F98B,
  "multiplication": F382,
  "multiplication-box": F383,
  "mushroom": F7DE,
  "mushroom-outline": F7DF,
  "music": F759,
  "music-accidental-double-flat": FF86,
  "music-accidental-double-sharp": FF87,
  "music-accidental-flat": FF88,
  "music-accidental-natural": FF89,
  "music-accidental-sharp": FF8A,
  "music-box": F384,
  "music-box-outline": F385,
  "music-circle": F386,
  "music-circle-outline": FAD3,
  "music-clef-alto": FF8B,
  "music-clef-bass": FF8C,
  "music-clef-treble": FF8D,
  "music-note": F387,
  "music-note-bluetooth": F5FE,
  "music-note-bluetooth-off": F5FF,
  "music-note-eighth": F388,
  "music-note-eighth-dotted": FF8E,
  "music-note-half": F389,
  "music-note-half-dotted": FF8F,
  "music-note-off": F38A,
  "music-note-off-outline": FF90,
  "music-note-outline": FF91,
  "music-note-plus": FDBA,
  "music-note-quarter": F38B,
  "music-note-quarter-dotted": FF92,
  "music-note-sixteenth": F38C,
  "music-note-sixteenth-dotted": FF93,
  "music-note-whole": F38D,
  "music-note-whole-dotted": FF94,
  "music-off": F75A,
  "music-rest-eighth": FF95,
  "music-rest-half": FF96,
  "music-rest-quarter": FF97,
  "music-rest-sixteenth": FF98,
  "music-rest-whole": FF99,
  "nail": FDBB,
  "nas": F8F2,
  "nativescript": F87F,
  "nature": F38E,
  "nature-people": F38F,
  "navigation": F390,
  "near-me": F5CD,
  "necklace": FF28,
  "needle": F391,
  "netflix": F745,
  "network": F6F2,
  "network-off": FC77,
  "network-off-outline": FC78,
  "network-outline": FC79,
  "network-strength-1": F8F3,
  "network-strength-1-alert": F8F4,
  "network-strength-2": F8F5,
  "network-strength-2-alert": F8F6,
  "network-strength-3": F8F7,
  "network-strength-3-alert": F8F8,
  "network-strength-4": F8F9,
  "network-strength-4-alert": F8FA,
  "network-strength-off": F8FB,
  "network-strength-off-outline": F8FC,
  "network-strength-outline": F8FD,
  "new-box": F394,
  "newspaper": F395,
  "newspaper-minus": FF29,
  "newspaper-plus": FF2A,
  "nfc": F396,
  "nfc-off": FE35,
  "nfc-search-variant": FE36,
  "nfc-tap": F397,
  "nfc-variant": F398,
  "nfc-variant-off": FE37,
  "ninja": F773,
  "nintendo-switch": F7E0,
  "nodejs": F399,
  "not-equal": F98C,
  "not-equal-variant": F98D,
  "note": F39A,
  "note-circle": FEDB,
  "note-multiple": F6B7,
  "note-multiple-outline": F6B8,
  "note-outline": F39B,
  "note-plus": F39C,
  "note-plus-outline": F39D,
  "note-text": F39E,
  "notebook": F82D,
  "notebook-multiple": FE38,
  "notebook-outline": FEDC,
  "notification-clear-all": F39F,
  "npm": F6F6,
  "npm-variant": F98E,
  "npm-variant-outline": F98F,
  "nuke": F6A3,
  "null": F7E1,
  "numeric": F3A0,
  "numeric-0": 0030,
  "numeric-0-box": F3A1,
  "numeric-0-box-multiple": FF2B,
  "numeric-0-box-multiple-outline": F3A2,
  "numeric-0-box-outline": F3A3,
  "numeric-0-circle": FC7A,
  "numeric-0-circle-outline": FC7B,
  "numeric-1": 0031,
  "numeric-1-box": F3A4,
  "numeric-1-box-multiple": FF2C,
  "numeric-1-box-multiple-outline": F3A5,
  "numeric-1-box-outline": F3A6,
  "numeric-1-circle": FC7C,
  "numeric-1-circle-outline": FC7D,
  "numeric-10-box": FF9A,
  "numeric-10-box-outline": FF9B,
  "numeric-2": 0032,
  "numeric-2-box": F3A7,
  "numeric-2-box-multiple": FF2D,
  "numeric-2-box-multiple-outline": F3A8,
  "numeric-2-box-outline": F3A9,
  "numeric-2-circle": FC7E,
  "numeric-2-circle-outline": FC7F,
  "numeric-3": 0033,
  "numeric-3-box": F3AA,
  "numeric-3-box-multiple": FF2E,
  "numeric-3-box-multiple-outline": F3AB,
  "numeric-3-box-outline": F3AC,
  "numeric-3-circle": FC80,
  "numeric-3-circle-outline": FC81,
  "numeric-4": 0034,
  "numeric-4-box": F3AD,
  "numeric-4-box-multiple": FF2F,
  "numeric-4-box-multiple-outline": F3AE,
  "numeric-4-box-outline": F3AF,
  "numeric-4-circle": FC82,
  "numeric-4-circle-outline": FC83,
  "numeric-5": 0035,
  "numeric-5-box": F3B0,
  "numeric-5-box-multiple": FF30,
  "numeric-5-box-multiple-outline": F3B1,
  "numeric-5-box-outline": F3B2,
  "numeric-5-circle": FC84,
  "numeric-5-circle-outline": FC85,
  "numeric-6": 0036,
  "numeric-6-box": F3B3,
  "numeric-6-box-multiple": FF31,
  "numeric-6-box-multiple-outline": F3B4,
  "numeric-6-box-outline": F3B5,
  "numeric-6-circle": FC86,
  "numeric-6-circle-outline": FC87,
  "numeric-7": 0037,
  "numeric-7-box": F3B6,
  "numeric-7-box-multiple": FF32,
  "numeric-7-box-multiple-outline": F3B7,
  "numeric-7-box-outline": F3B8,
  "numeric-7-circle": FC88,
  "numeric-7-circle-outline": FC89,
  "numeric-8": 0038,
  "numeric-8-box": F3B9,
  "numeric-8-box-multiple": FF33,
  "numeric-8-box-multiple-outline": F3BA,
  "numeric-8-box-outline": F3BB,
  "numeric-8-circle": FC8A,
  "numeric-8-circle-outline": FC8B,
  "numeric-9": 0039,
  "numeric-9-box": F3BC,
  "numeric-9-box-multiple": FF34,
  "numeric-9-box-multiple-outline": F3BD,
  "numeric-9-box-outline": F3BE,
  "numeric-9-circle": FC8C,
  "numeric-9-circle-outline": FC8D,
  "numeric-9-plus-box": F3BF,
  "numeric-9-plus-box-multiple": FF35,
  "numeric-9-plus-box-multiple-outline": F3C0,
  "numeric-9-plus-box-outline": F3C1,
  "numeric-9-plus-circle": FC8E,
  "numeric-9-plus-circle-outline": FC8F,
  "nut": F6F7,
  "nutrition": F3C2,
  "oar": F67B,
  "ocarina": FDBC,
  "octagon": F3C3,
  "octagon-outline": F3C4,
  "octagram": F6F8,
  "octagram-outline": F774,
  "odnoklassniki": F3C5,
  "office": F3C6,
  "office-building": F990,
  "oil": F3C7,
  "oil-lamp": FF36,
  "oil-temperature": F3C8,
  "omega": F3C9,
  "one-up": FB89,
  "onedrive": F3CA,
  "onenote": F746,
  "onepassword": F880,
  "opacity": F5CC,
  "open-in-app": F3CB,
  "open-in-new": F3CC,
  "open-source-initiative": FB8A,
  "openid": F3CD,
  "opera": F3CE,
  "orbit": F018,
  "origin": FB2B,
  "ornament": F3CF,
  "ornament-variant": F3D0,
  "outlook": FCFE,
  "owl": F3D2,
  "pac-man": FB8B,
  "package": F3D3,
  "package-down": F3D4,
  "package-up": F3D5,
  "package-variant": F3D6,
  "package-variant-closed": F3D7,
  "page-first": F600,
  "page-last": F601,
  "page-layout-body": F6F9,
  "page-layout-footer": F6FA,
  "page-layout-header": F6FB,
  "page-layout-header-footer": FF9C,
  "page-layout-sidebar-left": F6FC,
  "page-layout-sidebar-right": F6FD,
  "page-next": FB8C,
  "page-next-outline": FB8D,
  "page-previous": FB8E,
  "page-previous-outline": FB8F,
  "palette": F3D8,
  "palette-advanced": F3D9,
  "palette-outline": FE6C,
  "palette-swatch": F8B4,
  "pan": FB90,
  "pan-bottom-left": FB91,
  "pan-bottom-right": FB92,
  "pan-down": FB93,
  "pan-horizontal": FB94,
  "pan-left": FB95,
  "pan-right": FB96,
  "pan-top-left": FB97,
  "pan-top-right": FB98,
  "pan-up": FB99,
  "pan-vertical": FB9A,
  "panda": F3DA,
  "pandora": F3DB,
  "panorama": F3DC,
  "panorama-fisheye": F3DD,
  "panorama-horizontal": F3DE,
  "panorama-vertical": F3DF,
  "panorama-wide-angle": F3E0,
  "paper-cut-vertical": F3E1,
  "paperclip": F3E2,
  "parachute": FC90,
  "parachute-outline": FC91,
  "parking": F3E3,
  "passport": F7E2,
  "passport-biometric": FDBD,
  "patio-heater": FF9D,
  "patreon": F881,
  "pause": F3E4,
  "pause-circle": F3E5,
  "pause-circle-outline": F3E6,
  "pause-octagon": F3E7,
  "pause-octagon-outline": F3E8,
  "paw": F3E9,
  "paw-off": F657,
  "paypal": F882,
  "pdf-box": FE39,
  "peace": F883,
  "pen": F3EA,
  "pen-lock": FDBE,
  "pen-minus": FDBF,
  "pen-off": FDC0,
  "pen-plus": FDC1,
  "pen-remove": FDC2,
  "pencil": F3EB,
  "pencil-box": F3EC,
  "pencil-box-outline": F3ED,
  "pencil-circle": F6FE,
  "pencil-circle-outline": F775,
  "pencil-lock": F3EE,
  "pencil-lock-outline": FDC3,
  "pencil-minus": FDC4,
  "pencil-minus-outline": FDC5,
  "pencil-off": F3EF,
  "pencil-off-outline": FDC6,
  "pencil-outline": FC92,
  "pencil-plus": FDC7,
  "pencil-plus-outline": FDC8,
  "pencil-remove": FDC9,
  "pencil-remove-outline": FDCA,
  "penguin": FEDD,
  "pentagon": F6FF,
  "pentagon-outline": F700,
  "percent": F3F0,
  "periodic-table": F8B5,
  "periodic-table-co2": F7E3,
  "periscope": F747,
  "perspective-less": FCFF,
  "perspective-more": FD00,
  "pharmacy": F3F1,
  "phone": F3F2,
  "phone-alert": FF37,
  "phone-bluetooth": F3F3,
  "phone-classic": F602,
  "phone-forward": F3F4,
  "phone-hangup": F3F5,
  "phone-in-talk": F3F6,
  "phone-incoming": F3F7,
  "phone-lock": F3F8,
  "phone-log": F3F9,
  "phone-minus": F658,
  "phone-missed": F3FA,
  "phone-off": FDCB,
  "phone-outgoing": F3FB,
  "phone-outline": FDCC,
  "phone-paused": F3FC,
  "phone-plus": F659,
  "phone-return": F82E,
  "phone-rotate-landscape": F884,
  "phone-rotate-portrait": F885,
  "phone-settings": F3FD,
  "phone-voip": F3FE,
  "pi": F3FF,
  "pi-box": F400,
  "pi-hole": FDCD,
  "piano": F67C,
  "pickaxe": F8B6,
  "picture-in-picture-bottom-right": FE3A,
  "picture-in-picture-bottom-right-outline": FE3B,
  "picture-in-picture-top-right": FE3C,
  "picture-in-picture-top-right-outline": FE3D,
  "pier": F886,
  "pier-crane": F887,
  "pig": F401,
  "pill": F402,
  "pillar": F701,
  "pin": F403,
  "pin-off": F404,
  "pin-off-outline": F92F,
  "pin-outline": F930,
  "pine-tree": F405,
  "pine-tree-box": F406,
  "pinterest": F407,
  "pinterest-box": F408,
  "pinwheel": FAD4,
  "pinwheel-outline": FAD5,
  "pipe": F7E4,
  "pipe-disconnected": F7E5,
  "pipe-leak": F888,
  "pirate": FA07,
  "pistol": F702,
  "piston": F889,
  "pizza": F409,
  "play": F40A,
  "play-box-outline": F40B,
  "play-circle": F40C,
  "play-circle-outline": F40D,
  "play-network": F88A,
  "play-network-outline": FC93,
  "play-outline": FF38,
  "play-pause": F40E,
  "play-protected-content": F40F,
  "play-speed": F8FE,
  "playlist-check": F5C7,
  "playlist-edit": F8FF,
  "playlist-minus": F410,
  "playlist-music": FC94,
  "playlist-music-outline": FC95,
  "playlist-play": F411,
  "playlist-plus": F412,
  "playlist-remove": F413,
  "playlist-star": FDCE,
  "playstation": F414,
  "plex": F6B9,
  "plus": F415,
  "plus-box": F416,
  "plus-box-outline": F703,
  "plus-circle": F417,
  "plus-circle-multiple-outline": F418,
  "plus-circle-outline": F419,
  "plus-minus": F991,
  "plus-minus-box": F992,
  "plus-network": F41A,
  "plus-network-outline": FC96,
  "plus-one": F41B,
  "plus-outline": F704,
  "pocket": F41C,
  "podcast": F993,
  "podium": FD01,
  "podium-bronze": FD02,
  "podium-gold": FD03,
  "podium-silver": FD04,
  "point-of-sale": FD6E,
  "pokeball": F41D,
  "pokemon-go": FA08,
  "poker-chip": F82F,
  "polaroid": F41E,
  "poll": F41F,
  "poll-box": F420,
  "polymer": F421,
  "pool": F606,
  "popcorn": F422,
  "postage-stamp": FC97,
  "pot": F65A,
  "pot-mix": F65B,
  "pound": F423,
  "pound-box": F424,
  "power": F425,
  "power-cycle": F900,
  "power-off": F901,
  "power-on": F902,
  "power-plug": F6A4,
  "power-plug-off": F6A5,
  "power-settings": F426,
  "power-sleep": F903,
  "power-socket": F427,
  "power-socket-au": F904,
  "power-socket-eu": F7E6,
  "power-socket-uk": F7E7,
  "power-socket-us": F7E8,
  "power-standby": F905,
  "powershell": FA09,
  "prescription": F705,
  "presentation": F428,
  "presentation-play": F429,
  "printer": F42A,
  "printer-3d": F42B,
  "printer-3d-nozzle": FE3E,
  "printer-3d-nozzle-outline": FE3F,
  "printer-alert": F42C,
  "printer-off": FE40,
  "printer-settings": F706,
  "printer-wireless": FA0A,
  "priority-high": F603,
  "priority-low": F604,
  "professional-hexagon": F42D,
  "progress-alert": FC98,
  "progress-check": F994,
  "progress-clock": F995,
  "progress-download": F996,
  "progress-upload": F997,
  "progress-wrench": FC99,
  "projector": F42E,
  "projector-screen": F42F,
  "publish": F6A6,
  "pulse": F430,
  "pumpkin": FB9B,
  "purse": FF39,
  "purse-outline": FF3A,
  "puzzle": F431,
  "puzzle-outline": FA65,
  "qi": F998,
  "qqchat": F605,
  "qrcode": F432,
  "qrcode-edit": F8B7,
  "qrcode-scan": F433,
  "quadcopter": F434,
  "quality-high": F435,
  "quality-low": FA0B,
  "quality-medium": FA0C,
  "quicktime": F436,
  "quora": FD05,
  "rabbit": F906,
  "racing-helmet": FD6F,
  "racquetball": FD70,
  "radar": F437,
  "radiator": F438,
  "radiator-disabled": FAD6,
  "radiator-off": FAD7,
  "radio": F439,
  "radio-am": FC9A,
  "radio-fm": FC9B,
  "radio-handheld": F43A,
  "radio-tower": F43B,
  "radioactive": F43C,
  "radioactive-off": FEDE,
  "radiobox-blank": F43D,
  "radiobox-marked": F43E,
  "radius": FC9C,
  "radius-outline": FC9D,
  "railroad-light": FF3B,
  "raspberry-pi": F43F,
  "ray-end": F440,
  "ray-end-arrow": F441,
  "ray-start": F442,
  "ray-start-arrow": F443,
  "ray-start-end": F444,
  "ray-vertex": F445,
  "react": F707,
  "read": F447,
  "receipt": F449,
  "record": F44A,
  "record-circle": FEDF,
  "record-circle-outline": FEE0,
  "record-player": F999,
  "record-rec": F44B,
  "rectangle": FE41,
  "rectangle-outline": FE42,
  "recycle": F44C,
  "reddit": F44D,
  "redo": F44E,
  "redo-variant": F44F,
  "reflect-horizontal": FA0D,
  "reflect-vertical": FA0E,
  "refresh": F450,
  "regex": F451,
  "registered-trademark": FA66,
  "relative-scale": F452,
  "reload": F453,
  "reminder": F88B,
  "remote": F454,
  "remote-desktop": F8B8,
  "remote-off": FEE1,
  "remote-tv": FEE2,
  "remote-tv-off": FEE3,
  "rename-box": F455,
  "reorder-horizontal": F687,
  "reorder-vertical": F688,
  "repeat": F456,
  "repeat-off": F457,
  "repeat-once": F458,
  "replay": F459,
  "reply": F45A,
  "reply-all": F45B,
  "reply-all-outline": FF3C,
  "reply-outline": FF3D,
  "reproduction": F45C,
  "resistor": FB1F,
  "resistor-nodes": FB20,
  "resize": FA67,
  "resize-bottom-right": F45D,
  "responsive": F45E,
  "restart": F708,
  "restart-off": FD71,
  "restore": F99A,
  "restore-clock": F6A7,
  "rewind": F45F,
  "rewind-10": FD06,
  "rewind-30": FD72,
  "rewind-outline": F709,
  "rhombus": F70A,
  "rhombus-medium": FA0F,
  "rhombus-outline": F70B,
  "rhombus-split": FA10,
  "ribbon": F460,
  "rice": F7E9,
  "ring": F7EA,
  "rivet": FE43,
  "road": F461,
  "road-variant": F462,
  "robot": F6A8,
  "robot-industrial": FB21,
  "robot-vacuum": F70C,
  "robot-vacuum-variant": F907,
  "rocket": F463,
  "roller-skate": FD07,
  "rollerblade": FD08,
  "rollupjs": FB9C,
  "room-service": F88C,
  "room-service-outline": FD73,
  "rotate-3d": FEE4,
  "rotate-3d-variant": F464,
  "rotate-left": F465,
  "rotate-left-variant": F466,
  "rotate-orbit": FD74,
  "rotate-right": F467,
  "rotate-right-variant": F468,
  "rounded-corner": F607,
  "router-wireless": F469,
  "router-wireless-settings": FA68,
  "routes": F46A,
  "rowing": F608,
  "rss": F46B,
  "rss-box": F46C,
  "rss-off": FF3E,
  "ruby": FD09,
  "rugby": FD75,
  "ruler": F46D,
  "ruler-square": FC9E,
  "run": F70D,
  "run-fast": F46E,
  "sack": FD0A,
  "sack-percent": FD0B,
  "safe": FA69,
  "safety-goggles": FD0C,
  "sailing": FEE5,
  "sale": F46F,
  "salesforce": F88D,
  "sass": F7EB,
  "satellite": F470,
  "satellite-uplink": F908,
  "satellite-variant": F471,
  "sausage": F8B9,
  "saw-blade": FE44,
  "saxophone": F609,
  "scale": F472,
  "scale-balance": F5D1,
  "scale-bathroom": F473,
  "scanner": F6AA,
  "scanner-off": F909,
  "scatter-plot": FEE6,
  "scatter-plot-outline": FEE7,
  "school": F474,
  "scissors-cutting": FA6A,
  "screen-rotation": F475,
  "screen-rotation-lock": F476,
  "screw-flat-top": FDCF,
  "screw-lag": FE54,
  "screw-machine-flat-top": FE55,
  "screw-machine-round-top": FE56,
  "screw-round-top": FE57,
  "screwdriver": F477,
  "script": FB9D,
  "script-outline": F478,
  "script-text": FB9E,
  "script-text-outline": FB9F,
  "sd": F479,
  "seal": F47A,
  "search-web": F70E,
  "seat": FC9F,
  "seat-flat": F47B,
  "seat-flat-angled": F47C,
  "seat-individual-suite": F47D,
  "seat-legroom-extra": F47E,
  "seat-legroom-normal": F47F,
  "seat-legroom-reduced": F480,
  "seat-outline": FCA0,
  "seat-recline-extra": F481,
  "seat-recline-normal": F482,
  "seatbelt": FCA1,
  "security": F483,
  "security-network": F484,
  "seed": FE45,
  "seed-outline": FE46,
  "segment": FEE8,
  "select": F485,
  "select-all": F486,
  "select-color": FD0D,
  "select-compare": FAD8,
  "select-drag": FA6B,
  "select-group": FF9F,
  "select-inverse": F487,
  "select-off": F488,
  "selection": F489,
  "selection-drag": FA6C,
  "selection-ellipse": FD0E,
  "selection-ellipse-arrow-inside": FF3F,
  "selection-off": F776,
  "send": F48A,
  "send-circle": FE58,
  "send-circle-outline": FE59,
  "send-lock": F7EC,
  "serial-port": F65C,
  "server": F48B,
  "server-minus": F48C,
  "server-network": F48D,
  "server-network-off": F48E,
  "server-off": F48F,
  "server-plus": F490,
  "server-remove": F491,
  "server-security": F492,
  "set-all": F777,
  "set-center": F778,
  "set-center-right": F779,
  "set-left": F77A,
  "set-left-center": F77B,
  "set-left-right": F77C,
  "set-none": F77D,
  "set-right": F77E,
  "set-top-box": F99E,
  "settings": F493,
  "settings-box": F494,
  "settings-helper": FA6D,
  "settings-outline": F8BA,
  "shape": F830,
  "shape-circle-plus": F65D,
  "shape-outline": F831,
  "shape-plus": F495,
  "shape-polygon-plus": F65E,
  "shape-rectangle-plus": F65F,
  "shape-square-plus": F660,
  "share": F496,
  "share-off": FF40,
  "share-off-outline": FF41,
  "share-outline": F931,
  "share-variant": F497,
  "sheep": FCA2,
  "shield": F498,
  "shield-account": F88E,
  "shield-account-outline": FA11,
  "shield-airplane": F6BA,
  "shield-airplane-outline": FCA3,
  "shield-alert": FEE9,
  "shield-alert-outline": FEEA,
  "shield-car": FFA0,
  "shield-check": F565,
  "shield-check-outline": FCA4,
  "shield-cross": FCA5,
  "shield-cross-outline": FCA6,
  "shield-half-full": F77F,
  "shield-home": F689,
  "shield-home-outline": FCA7,
  "shield-key": FBA0,
  "shield-key-outline": FBA1,
  "shield-link-variant": FD0F,
  "shield-link-variant-outline": FD10,
  "shield-lock": F99C,
  "shield-lock-outline": FCA8,
  "shield-off": F99D,
  "shield-off-outline": F99B,
  "shield-outline": F499,
  "shield-plus": FAD9,
  "shield-plus-outline": FADA,
  "shield-remove": FADB,
  "shield-remove-outline": FADC,
  "shield-search": FD76,
  "ship-wheel": F832,
  "shoe-formal": FB22,
  "shoe-heel": FB23,
  "shoe-print": FE5A,
  "shopify": FADD,
  "shopping": F49A,
  "shopping-music": F49B,
  "shopping-search": FFA1,
  "shovel": F70F,
  "shovel-off": F710,
  "shower": F99F,
  "shower-head": F9A0,
  "shredder": F49C,
  "shuffle": F49D,
  "shuffle-disabled": F49E,
  "shuffle-variant": F49F,
  "sigma": F4A0,
  "sigma-lower": F62B,
  "sign-caution": F4A1,
  "sign-direction": F780,
  "sign-text": F781,
  "signal": F4A2,
  "signal-2g": F711,
  "signal-3g": F712,
  "signal-4g": F713,
  "signal-5g": FA6E,
  "signal-cellular-1": F8BB,
  "signal-cellular-2": F8BC,
  "signal-cellular-3": F8BD,
  "signal-cellular-outline": F8BE,
  "signal-distance-variant": FE47,
  "signal-hspa": F714,
  "signal-hspa-plus": F715,
  "signal-off": F782,
  "signal-variant": F60A,
  "signature": FE5B,
  "signature-freehand": FE5C,
  "signature-image": FE5D,
  "signature-text": FE5E,
  "silo": FB24,
  "silverware": F4A3,
  "silverware-fork": F4A4,
  "silverware-fork-knife": FA6F,
  "silverware-spoon": F4A5,
  "silverware-variant": F4A6,
  "sim": F4A7,
  "sim-alert": F4A8,
  "sim-off": F4A9,
  "sina-weibo": FADE,
  "sitemap": F4AA,
  "skate": FD11,
  "skew-less": FD12,
  "skew-more": FD13,
  "skip-backward": F4AB,
  "skip-backward-outline": FF42,
  "skip-forward": F4AC,
  "skip-forward-outline": FF43,
  "skip-next": F4AD,
  "skip-next-circle": F661,
  "skip-next-circle-outline": F662,
  "skip-next-outline": FF44,
  "skip-previous": F4AE,
  "skip-previous-circle": F663,
  "skip-previous-circle-outline": F664,
  "skip-previous-outline": FF45,
  "skull": F68B,
  "skull-crossbones": FBA2,
  "skull-crossbones-outline": FBA3,
  "skull-outline": FBA4,
  "skype": F4AF,
  "skype-business": F4B0,
  "slack": F4B1,
  "slackware": F90A,
  "sleep": F4B2,
  "sleep-off": F4B3,
  "slope-downhill": FE5F,
  "slope-uphill": FE60,
  "smog": FA70,
  "smoke-detector": F392,
  "smoking": F4B4,
  "smoking-off": F4B5,
  "snapchat": F4B6,
  "snowflake": F716,
  "snowflake-alert": FF46,
  "snowflake-variant": FF47,
  "snowman": F4B7,
  "soccer": F4B8,
  "soccer-field": F833,
  "sofa": F4B9,
  "solar-panel": FD77,
  "solar-panel-large": FD78,
  "solar-power": FA71,
  "solid": F68C,
  "sort": F4BA,
  "sort-alphabetical": F4BB,
  "sort-ascending": F4BC,
  "sort-descending": F4BD,
  "sort-numeric": F4BE,
  "sort-variant": F4BF,
  "sort-variant-lock": FCA9,
  "sort-variant-lock-open": FCAA,
  "soundcloud": F4C0,
  "source-branch": F62C,
  "source-commit": F717,
  "source-commit-end": F718,
  "source-commit-end-local": F719,
  "source-commit-local": F71A,
  "source-commit-next-local": F71B,
  "source-commit-start": F71C,
  "source-commit-start-next-local": F71D,
  "source-fork": F4C1,
  "source-merge": F62D,
  "source-pull": F4C2,
  "source-repository": FCAB,
  "source-repository-multiple": FCAC,
  "soy-sauce": F7ED,
  "spa": FCAD,
  "spa-outline": FCAE,
  "space-invaders": FBA5,
  "spade": FE48,
  "speaker": F4C3,
  "speaker-bluetooth": F9A1,
  "speaker-multiple": FD14,
  "speaker-off": F4C4,
  "speaker-wireless": F71E,
  "speedometer": F4C5,
  "speedometer-medium": FFA2,
  "speedometer-slow": FFA3,
  "spellcheck": F4C6,
  "spider-web": FBA6,
  "spotify": F4C7,
  "spotlight": F4C8,
  "spotlight-beam": F4C9,
  "spray": F665,
  "spray-bottle": FADF,
  "sprout": FE49,
  "sprout-outline": FE4A,
  "square": F763,
  "square-edit-outline": F90B,
  "square-inc": F4CA,
  "square-inc-cash": F4CB,
  "square-medium": FA12,
  "square-medium-outline": FA13,
  "square-outline": F762,
  "square-root": F783,
  "square-root-box": F9A2,
  "square-small": FA14,
  "squeegee": FAE0,
  "ssh": F8BF,
  "stack-exchange": F60B,
  "stack-overflow": F4CC,
  "stadium": F71F,
  "stairs": F4CD,
  "stamper": FD15,
  "standard-definition": F7EE,
  "star": F4CE,
  "star-box": FA72,
  "star-box-outline": FA73,
  "star-circle": F4CF,
  "star-circle-outline": F9A3,
  "star-face": F9A4,
  "star-four-points": FAE1,
  "star-four-points-outline": FAE2,
  "star-half": F4D0,
  "star-off": F4D1,
  "star-outline": F4D2,
  "star-three-points": FAE3,
  "star-three-points-outline": FAE4,
  "steam": F4D3,
  "steam-box": F90C,
  "steering": F4D4,
  "steering-off": F90D,
  "step-backward": F4D5,
  "step-backward-2": F4D6,
  "step-forward": F4D7,
  "step-forward-2": F4D8,
  "stethoscope": F4D9,
  "sticker": F5D0,
  "sticker-emoji": F784,
  "stocking": F4DA,
  "stop": F4DB,
  "stop-circle": F666,
  "stop-circle-outline": F667,
  "store": F4DC,
  "store-24-hour": F4DD,
  "stove": F4DE,
  "strava": FB25,
  "stretch-to-page": FF48,
  "stretch-to-page-outline": FF49,
  "subdirectory-arrow-left": F60C,
  "subdirectory-arrow-right": F60D,
  "subtitles": FA15,
  "subtitles-outline": FA16,
  "subway": F6AB,
  "subway-alert-variant": FD79,
  "subway-variant": F4DF,
  "summit": F785,
  "sunglasses": F4E0,
  "surround-sound": F5C5,
  "surround-sound-2-0": F7EF,
  "surround-sound-3-1": F7F0,
  "surround-sound-5-1": F7F1,
  "surround-sound-7-1": F7F2,
  "svg": F720,
  "swap-horizontal": F4E1,
  "swap-horizontal-bold": FBA9,
  "swap-horizontal-variant": F8C0,
  "swap-vertical": F4E2,
  "swap-vertical-bold": FBAA,
  "swap-vertical-variant": F8C1,
  "swim": F4E3,
  "switch": F4E4,
  "sword": F4E5,
  "sword-cross": F786,
  "symfony": FAE5,
  "sync": F4E6,
  "sync-alert": F4E7,
  "sync-off": F4E8,
  "tab": F4E9,
  "tab-minus": FB26,
  "tab-plus": F75B,
  "tab-remove": FB27,
  "tab-unselected": F4EA,
  "table": F4EB,
  "table-border": FA17,
  "table-column": F834,
  "table-column-plus-after": F4EC,
  "table-column-plus-before": F4ED,
  "table-column-remove": F4EE,
  "table-column-width": F4EF,
  "table-edit": F4F0,
  "table-large": F4F1,
  "table-large-plus": FFA4,
  "table-large-remove": FFA5,
  "table-merge-cells": F9A5,
  "table-of-contents": F835,
  "table-plus": FA74,
  "table-remove": FA75,
  "table-row": F836,
  "table-row-height": F4F2,
  "table-row-plus-after": F4F3,
  "table-row-plus-before": F4F4,
  "table-row-remove": F4F5,
  "table-search": F90E,
  "table-settings": F837,
  "table-tennis": FE4B,
  "tablet": F4F6,
  "tablet-android": F4F7,
  "tablet-cellphone": F9A6,
  "tablet-dashboard": FEEB,
  "tablet-ipad": F4F8,
  "taco": F761,
  "tag": F4F9,
  "tag-faces": F4FA,
  "tag-heart": F68A,
  "tag-heart-outline": FBAB,
  "tag-minus": F90F,
  "tag-multiple": F4FB,
  "tag-outline": F4FC,
  "tag-plus": F721,
  "tag-remove": F722,
  "tag-text-outline": F4FD,
  "tank": FD16,
  "tape-measure": FB28,
  "target": F4FE,
  "target-account": FBAC,
  "target-variant": FA76,
  "taxi": F4FF,
  "tea": FD7A,
  "tea-outline": FD7B,
  "teach": F88F,
  "teamviewer": F500,
  "telegram": F501,
  "telescope": FB29,
  "television": F502,
  "television-box": F838,
  "television-classic": F7F3,
  "television-classic-off": F839,
  "television-guide": F503,
  "television-off": F83A,
  "television-pause": FFA6,
  "television-play": FEEC,
  "television-stop": FFA7,
  "temperature-celsius": F504,
  "temperature-fahrenheit": F505,
  "temperature-kelvin": F506,
  "tennis": FD7C,
  "tennis-ball": F507,
  "tent": F508,
  "terrain": F509,
  "test-tube": F668,
  "test-tube-empty": F910,
  "test-tube-off": F911,
  "text": F9A7,
  "text-shadow": F669,
  "text-short": F9A8,
  "text-subject": F9A9,
  "text-to-speech": F50A,
  "text-to-speech-off": F50B,
  "textbox": F60E,
  "textbox-password": F7F4,
  "texture": F50C,
  "theater": F50D,
  "theme-light-dark": F50E,
  "thermometer": F50F,
  "thermometer-alert": FE61,
  "thermometer-chevron-down": FE62,
  "thermometer-chevron-up": FE63,
  "thermometer-lines": F510,
  "thermometer-minus": FE64,
  "thermometer-plus": FE65,
  "thermostat": F393,
  "thermostat-box": F890,
  "thought-bubble": F7F5,
  "thought-bubble-outline": F7F6,
  "thumb-down": F511,
  "thumb-down-outline": F512,
  "thumb-up": F513,
  "thumb-up-outline": F514,
  "thumbs-up-down": F515,
  "ticket": F516,
  "ticket-account": F517,
  "ticket-confirmation": F518,
  "ticket-outline": F912,
  "ticket-percent": F723,
  "tie": F519,
  "tilde": F724,
  "timelapse": F51A,
  "timeline": FBAD,
  "timeline-alert": FFB2,
  "timeline-alert-outline": FFB5,
  "timeline-help": FFB6,
  "timeline-help-outline": FFB7,
  "timeline-outline": FBAE,
  "timeline-plus": FFB3,
  "timeline-plus-outline": FFB4,
  "timeline-text": FBAF,
  "timeline-text-outline": FBB0,
  "timer": F51B,
  "timer-10": F51C,
  "timer-3": F51D,
  "timer-off": F51E,
  "timer-sand": F51F,
  "timer-sand-empty": F6AC,
  "timer-sand-full": F78B,
  "timetable": F520,
  "toaster-oven": FCAF,
  "toggle-switch": F521,
  "toggle-switch-off": F522,
  "toggle-switch-off-outline": FA18,
  "toggle-switch-outline": FA19,
  "toilet": F9AA,
  "toolbox": F9AB,
  "toolbox-outline": F9AC,
  "tooltip": F523,
  "tooltip-account": F00C,
  "tooltip-edit": F524,
  "tooltip-image": F525,
  "tooltip-image-outline": FBB1,
  "tooltip-outline": F526,
  "tooltip-plus": FBB2,
  "tooltip-plus-outline": F527,
  "tooltip-text": F528,
  "tooltip-text-outline": FBB3,
  "tooth": F8C2,
  "tooth-outline": F529,
  "tor": F52A,
  "tortoise": FD17,
  "tournament": F9AD,
  "tower-beach": F680,
  "tower-fire": F681,
  "towing": F83B,
  "track-light": F913,
  "trackpad": F7F7,
  "trackpad-lock": F932,
  "tractor": F891,
  "trademark": FA77,
  "traffic-light": F52B,
  "train": F52C,
  "train-car": FBB4,
  "train-variant": F8C3,
  "tram": F52D,
  "transcribe": F52E,
  "transcribe-close": F52F,
  "transfer-down": FD7D,
  "transfer-left": FD7E,
  "transfer-right": F530,
  "transfer-up": FD7F,
  "transit-connection": FD18,
  "transit-connection-variant": FD19,
  "transit-detour": FFA8,
  "transit-transfer": F6AD,
  "transition": F914,
  "transition-masked": F915,
  "translate": F5CA,
  "translate-off": FE66,
  "transmission-tower": FD1A,
  "trash-can": FA78,
  "trash-can-outline": FA79,
  "treasure-chest": F725,
  "tree": F531,
  "tree-outline": FE4C,
  "trello": F532,
  "trending-down": F533,
  "trending-neutral": F534,
  "trending-up": F535,
  "triangle": F536,
  "triangle-outline": F537,
  "triforce": FBB5,
  "trophy": F538,
  "trophy-award": F539,
  "trophy-broken": FD80,
  "trophy-outline": F53A,
  "trophy-variant": F53B,
  "trophy-variant-outline": F53C,
  "truck": F53D,
  "truck-check": FCB0,
  "truck-delivery": F53E,
  "truck-fast": F787,
  "truck-trailer": F726,
  "tshirt-crew": FA7A,
  "tshirt-crew-outline": F53F,
  "tshirt-v": FA7B,
  "tshirt-v-outline": F540,
  "tumble-dryer": F916,
  "tumblr": F541,
  "tumblr-box": F917,
  "tumblr-reblog": F542,
  "tune": F62E,
  "tune-vertical": F66A,
  "turnstile": FCB1,
  "turnstile-outline": FCB2,
  "turtle": FCB3,
  "twitch": F543,
  "twitter": F544,
  "twitter-box": F545,
  "twitter-circle": F546,
  "twitter-retweet": F547,
  "two-factor-authentication": F9AE,
  "typewriter": FF4A,
  "uber": F748,
  "ubisoft": FBB6,
  "ubuntu": F548,
  "ultra-high-definition": F7F8,
  "umbraco": F549,
  "umbrella": F54A,
  "umbrella-closed": F9AF,
  "umbrella-outline": F54B,
  "undo": F54C,
  "undo-variant": F54D,
  "unfold-less-horizontal": F54E,
  "unfold-less-vertical": F75F,
  "unfold-more-horizontal": F54F,
  "unfold-more-vertical": F760,
  "ungroup": F550,
  "unicode": FEED,
  "unity": F6AE,
  "unreal": F9B0,
  "untappd": F551,
  "update": F6AF,
  "upload": F552,
  "upload-multiple": F83C,
  "upload-network": F6F5,
  "upload-network-outline": FCB4,
  "upload-outline": FE67,
  "usb": F553,
  "van-passenger": F7F9,
  "van-utility": F7FA,
  "vanish": F7FB,
  "variable": FAE6,
  "vector-arrange-above": F554,
  "vector-arrange-below": F555,
  "vector-bezier": FAE7,
  "vector-circle": F556,
  "vector-circle-variant": F557,
  "vector-combine": F558,
  "vector-curve": F559,
  "vector-difference": F55A,
  "vector-difference-ab": F55B,
  "vector-difference-ba": F55C,
  "vector-ellipse": F892,
  "vector-intersection": F55D,
  "vector-line": F55E,
  "vector-point": F55F,
  "vector-polygon": F560,
  "vector-polyline": F561,
  "vector-radius": F749,
  "vector-rectangle": F5C6,
  "vector-selection": F562,
  "vector-square": F001,
  "vector-triangle": F563,
  "vector-union": F564,
  "venmo": F578,
  "vhs": FA1A,
  "vibrate": F566,
  "vibrate-off": FCB5,
  "video": F567,
  "video-3d": F7FC,
  "video-3d-variant": FEEE,
  "video-4k-box": F83D,
  "video-account": F918,
  "video-image": F919,
  "video-input-antenna": F83E,
  "video-input-component": F83F,
  "video-input-hdmi": F840,
  "video-input-scart": FFA9,
  "video-input-svideo": F841,
  "video-minus": F9B1,
  "video-off": F568,
  "video-off-outline": FBB7,
  "video-outline": FBB8,
  "video-plus": F9B2,
  "video-stabilization": F91A,
  "video-switch": F569,
  "video-vintage": FA1B,
  "video-wireless": FEEF,
  "video-wireless-outline": FEF0,
  "view-agenda": F56A,
  "view-array": F56B,
  "view-carousel": F56C,
  "view-column": F56D,
  "view-comfy": FE4D,
  "view-compact": FE4E,
  "view-compact-outline": FE4F,
  "view-dashboard": F56E,
  "view-dashboard-outline": FA1C,
  "view-dashboard-variant": F842,
  "view-day": F56F,
  "view-grid": F570,
  "view-grid-plus": FFAA,
  "view-headline": F571,
  "view-list": F572,
  "view-module": F573,
  "view-parallel": F727,
  "view-quilt": F574,
  "view-sequential": F728,
  "view-split-horizontal": FBA7,
  "view-split-vertical": FBA8,
  "view-stream": F575,
  "view-week": F576,
  "vimeo": F577,
  "violin": F60F,
  "virtual-reality": F893,
  "visual-studio": F610,
  "visual-studio-code": FA1D,
  "vk": F579,
  "vk-box": F57A,
  "vk-circle": F57B,
  "vlc": F57C,
  "voice": F5CB,
  "voice-off": FEF1,
  "voicemail": F57D,
  "volleyball": F9B3,
  "volume-high": F57E,
  "volume-low": F57F,
  "volume-medium": F580,
  "volume-minus": F75D,
  "volume-mute": F75E,
  "volume-off": F581,
  "volume-plus": F75C,
  "volume-variant-off": FE68,
  "vote": FA1E,
  "vote-outline": FA1F,
  "vpn": F582,
  "vuejs": F843,
  "vuetify": FE50,
  "walk": F583,
  "wall": F7FD,
  "wall-sconce": F91B,
  "wall-sconce-flat": F91C,
  "wall-sconce-variant": F91D,
  "wallet": F584,
  "wallet-giftcard": F585,
  "wallet-membership": F586,
  "wallet-outline": FBB9,
  "wallet-plus": FFAB,
  "wallet-plus-outline": FFAC,
  "wallet-travel": F587,
  "wallpaper": FE69,
  "wan": F588,
  "wardrobe": FFAD,
  "wardrobe-outline": FFAE,
  "warehouse": FFBB,
  "washing-machine": F729,
  "watch": F589,
  "watch-export": F58A,
  "watch-export-variant": F894,
  "watch-import": F58B,
  "watch-import-variant": F895,
  "watch-variant": F896,
  "watch-vibrate": F6B0,
  "watch-vibrate-off": FCB6,
  "water": F58C,
  "water-boiler": FFAF,
  "water-off": F58D,
  "water-outline": FE6A,
  "water-percent": F58E,
  "water-pump": F58F,
  "water-pump-off": FFB0,
  "watermark": F612,
  "wave": FF4B,
  "waves": F78C,
  "waze": FBBA,
  "weather-cloudy": F590,
  "weather-cloudy-alert": FF4C,
  "weather-cloudy-arrow-right": FE51,
  "weather-fog": F591,
  "weather-hail": F592,
  "weather-hazy": FF4D,
  "weather-hurricane": F897,
  "weather-lightning": F593,
  "weather-lightning-rainy": F67D,
  "weather-night": F594,
  "weather-night-partly-cloudy": FF4E,
  "weather-partly-cloudy": F595,
  "weather-partly-lightning": FF4F,
  "weather-partly-rainy": FF50,
  "weather-partly-snowy": FF51,
  "weather-partly-snowy-rainy": FF52,
  "weather-pouring": F596,
  "weather-rainy": F597,
  "weather-snowy": F598,
  "weather-snowy-heavy": FF53,
  "weather-snowy-rainy": F67E,
  "weather-sunny": F599,
  "weather-sunny-alert": FF54,
  "weather-sunset": F59A,
  "weather-sunset-down": F59B,
  "weather-sunset-up": F59C,
  "weather-tornado": FF55,
  "weather-windy": F59D,
  "weather-windy-variant": F59E,
  "web": F59F,
  "web-box": FFB1,
  "webcam": F5A0,
  "webhook": F62F,
  "webpack": F72A,
  "wechat": F611,
  "weight": F5A1,
  "weight-gram": FD1B,
  "weight-kilogram": F5A2,
  "weight-pound": F9B4,
  "whatsapp": F5A3,
  "wheelchair-accessibility": F5A4,
  "whistle": F9B5,
  "white-balance-auto": F5A5,
  "white-balance-incandescent": F5A6,
  "white-balance-iridescent": F5A7,
  "white-balance-sunny": F5A8,
  "widgets": F72B,
  "wifi": F5A9,
  "wifi-off": F5AA,
  "wifi-star": FE6B,
  "wifi-strength-1": F91E,
  "wifi-strength-1-alert": F91F,
  "wifi-strength-1-lock": F920,
  "wifi-strength-2": F921,
  "wifi-strength-2-alert": F922,
  "wifi-strength-2-lock": F923,
  "wifi-strength-3": F924,
  "wifi-strength-3-alert": F925,
  "wifi-strength-3-lock": F926,
  "wifi-strength-4": F927,
  "wifi-strength-4-alert": F928,
  "wifi-strength-4-lock": F929,
  "wifi-strength-alert-outline": F92A,
  "wifi-strength-lock-outline": F92B,
  "wifi-strength-off": F92C,
  "wifi-strength-off-outline": F92D,
  "wifi-strength-outline": F92E,
  "wii": F5AB,
  "wiiu": F72C,
  "wikipedia": F5AC,
  "wind-turbine": FD81,
  "window-close": F5AD,
  "window-closed": F5AE,
  "window-maximize": F5AF,
  "window-minimize": F5B0,
  "window-open": F5B1,
  "window-restore": F5B2,
  "windows": F5B3,
  "windows-classic": FA20,
  "wiper": FAE8,
  "wiper-wash": FD82,
  "wordpress": F5B4,
  "worker": F5B5,
  "wrap": F5B6,
  "wrap-disabled": FBBB,
  "wrench": F5B7,
  "wrench-outline": FBBC,
  "wunderlist": F5B8,
  "xamarin": F844,
  "xamarin-outline": F845,
  "xaml": F673,
  "xbox": F5B9,
  "xbox-controller": F5BA,
  "xbox-controller-battery-alert": F74A,
  "xbox-controller-battery-charging": FA21,
  "xbox-controller-battery-empty": F74B,
  "xbox-controller-battery-full": F74C,
  "xbox-controller-battery-low": F74D,
  "xbox-controller-battery-medium": F74E,
  "xbox-controller-battery-unknown": F74F,
  "xbox-controller-menu": FE52,
  "xbox-controller-off": F5BB,
  "xbox-controller-view": FE53,
  "xda": F5BC,
  "xing": F5BD,
  "xing-box": F5BE,
  "xing-circle": F5BF,
  "xml": F5C0,
  "xmpp": F7FE,
  "yahoo": FB2A,
  "yammer": F788,
  "yeast": F5C1,
  "yelp": F5C2,
  "yin-yang": F67F,
  "youtube": F5C3,
  "youtube-creator-studio": F846,
  "youtube-gaming": F847,
  "youtube-subscription": FD1C,
  "youtube-tv": F448,
  "z-wave": FAE9,
  "zend": FAEA,
  "zigbee": FD1D,
  "zip-box": F5C4,
  "zip-disk": FA22,
  "zodiac-aquarius": FA7C,
  "zodiac-aries": FA7D,
  "zodiac-cancer": FA7E,
  "zodiac-capricorn": FA7F,
  "zodiac-gemini": FA80,
  "zodiac-leo": FA81,
  "zodiac-libra": FA82,
  "zodiac-pisces": FA83,
  "zodiac-sagittarius": FA84,
  "zodiac-scorpio": FA85,
  "zodiac-taurus": FA86,
  "zodiac-virgo": FA87
);