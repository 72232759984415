// out: false, main: ../style.scss
input,
select,
textarea {
  padding: .5rem;
  margin-bottom: .5rem;
  display: block;
  width: 100%;
  box-shadow: none;
}

input[type="submit"] { margin-top: .85rem; }

input[type="checkbox"] {
  display: inline-block;
  vertical-align: bottom;
  width: auto;
}
