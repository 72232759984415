// out: false, main: ../style.scss

///////////////////////////////////////
////////// CSS Parallax ///////////////
///////////////////////////////////////

$perspective: 8px;

@function parallax-scale-factor($z){
  @return ($perspective - $z) / $perspective;
}

// Floating Elements
// .parallax-container {
//   &--outer {
//     position: relative;
//     height: 100vh;
//     overflow-x: hidden;
//     overflow-y: scroll;
//     perspective: $perspective;
//     perspective-origin: 0%;
//     -webkit-overflow-scrolling: touch;
//     -ms-overflow-style: none;
//     transform-style: preserve-3d;
//   }
//   &--el {
//     position: absolute; 
//   }
//   &--layer-1 {
//     transform: translateZ(4px) scale(parallax-scale-factor(4));
//   }
//   &--layer-2 {
//     transform: translateZ(8px) scale(parallax-scale-factor(8));
//   }
//   &--layer-3 {
//     transform: translateZ(16px) scale(parallax-scale-factor(16));
//   }
// }

.parallax-container {
  &--outer {
    // position: relative;
    // height: 100vh;
    // overflow-x: hidden;
    // overflow-y: scroll;
    // perspective: $perspective;
    // perspective-origin: 0%;
    // -webkit-overflow-scrolling: touch;
    // -ms-overflow-style: none;
    // transform-style: preserve-3d;
    height: 100vh;
    overflow-x: hidden; 
    overflow-y: auto;
    -webkit-perspective: 300px;
    perspective: 300px; 
    -webkit-perspective-origin-x: 100%;
    perspective-origin-x: 100%;
  }
  &--el {
    position: absolute; 
  }
  &--layer-1 {
    transform: translateZ(4px) scale(parallax-scale-factor(4));
  }
  &--layer-2 {
    transform: translateZ(8px) scale(parallax-scale-factor(8));
  }
  &--layer-3 {
    transform: translateZ(16px) scale(parallax-scale-factor(16));
  }
}


