// out: false, main: ../style.scss

.headerswiper-container {
  position: relative;
  #headerswiper {
    overflow: hidden;
    @media(min-width: $viewport-xl){
      height: 45vw;
      max-height: 1024px;
    }
    .swiper-slide {
      position: relative;
      @media(min-width: $viewport-xl){
        height: 50vw;
        max-height: 100%;
      }
      &.swiper-slide-active {
        @media (min-width: $viewport-xl){
          .text {
            .inner {
              opacity: 0;
              animation-name: slideInAnim;
              animation-fill-mode: forwards;
              animation-timing-function: ease;
              animation-duration: 0.5s;
              animation-delay: 0.5s;
            }
          }
        }
      }
      .text {
        max-width: 50rem;
        color: $black;
        @media (min-width: $viewport-l){
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $white;
        }
      }
      .objectfit-img {
        object-fit: cover;
        height: auto;
        max-height: 253px;
        @media (min-width: $viewport-l){
          max-height: 495px;
        }
        @media (min-width: $viewport-xl){
          height: 100%;
          max-height: none;
        }
      }
    }
  }
  .headerswiper-pagination {
    position: relative;
    bottom: 0;
    margin-bottom: 1rem;
    @media (min-width: $viewport-xl){
      position: absolute;
    }
    .swiper-pagination-bullet {
      opacity: 0.8;
      width: 15px;
      height: 15px;
      margin: 0.3rem;
      outline: 0;
      @media (min-width: $viewport-xl){
      }
      &.swiper-pagination-bullet-active {
        background-color: $white;
      }
    }
  }
  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    outline: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 53px;
    width: 72px;
  } 
  .swiper-button-next {
    right: 1rem;
    left: auto;
    background-image: url("../images/icon-slider-right.png");
    @supports (color: black){
      background-image: url("../images/icon-slider-right.svg");
    }
  }
  .swiper-button-prev {
    left: 1rem;
    right: auto;
    background-image: url("../images/icon-slider-left.png");
    @supports (color: black){
      background-image: url("../images/icon-slider-left.svg");
    }
  }
}

@keyframes slideInAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}