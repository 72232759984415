// out: false, main: ../style.scss

// NOTE Es sind die alten Klassen --dark und --light weiterhin aufgeführt, falls sie noch irgendwo verwendet werden

.color-1 {
  background-color: $color-1;
  &--l2 {
    background-color: $color-1--l2;
  }
  &--l1 {
    background-color: $color-1--l1;
  }
  &--d1 {
    background-color: $color-1--d1;
  }
  &--d2 {
    background-color: $color-1--d2;
  }
}

.color-2 {
  background-color: $color-2;
  &--l2 {
    background-color: $color-2--l2;
  }
  &--l1 {
    background-color: $color-2--l1;
  }
  &--d1 {
    background-color: $color-2--d1;
  }
  &--d2 {
    background-color: $color-2--d2;
  }
}

.color-3 {
  background-color: $color-3;
  &--l2 {
    background-color: $color-3--l2;
  }
  &--l1 {
    background-color: $color-3--l1;
  }
  &--d1 {
    background-color: $color-3--d1;
  }
  &--d2 {
    background-color: $color-3--d2;
  }
}

.color-4 {
  background-color: $color-4;
  &--l2 {
    background-color: $color-4--l2;
  }
  &--l1 {
    background-color: $color-4--l1;
  }
  &--d1 {
    background-color: $color-4--d1;
  }
  &--d2 {
    background-color: $color-4--d2;
  }
}

.color-white {
  background-color: $color-white;
}
.color-black {
  background-color: $color-black;
}
.color-0 {
  background-color: $color-0;
  &--l3 {
    background-color: $color-0--l3;
  }
  &--l2 {
    background-color: $color-0--l2;
  }
  &--l1 {
    background-color: $color-0--l1;
  }
  &--d1 {
    background-color: $color-0--d1;
  }
  &--d2 {
    background-color: $color-0--d2;
  }
  &--d3 {
    background-color: $color-0--d3;
  }
}

// FONT COLORS
.font-color-white { 
  color: $font-color-white;
}
.font-color-0 { 
  color: $font-color-0;
}
.font-color-1 {
  color: $font-color-1;
}/*
.font-color-2 {
  color: $font-color-2;
}
.font-color-3 {
  color: $font-color-3;
}
.font-color-4 {
  color: $font-color-4;
}
.font-color-5 {
  color: $font-color-5;
}



.grad-1 {
  background: $grad-1;
}

.grad-2 {
  background: $grad-2;
}

.grad-3 {
  background: $grad-3;
}

.grad-4 {
  background: $grad-4;
}

.grad-5 {
  background: $grad-5;
}

.grad-6 {
  background: $grad-6;
}

.grad-7 {
  background: $grad-7;
}

.grad-8 {
  background: $grad-8;
}*/