// out: false, main: ../style.scss
$footer-size: 30rem;
// Container der alles außer den Footer umschließt
.content--fixed-bottom-padding {
  margin-bottom: $footer-size;
}
.fixed-bottom-el {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: $footer-size;
  z-index: -1;
}