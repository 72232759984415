// out: false, main: ../style.scss

////////////////////////////////////////////
// ************* DEBUG MODE ************ //
//////////////////////////////////////////
$debug-mode: false !default;


// ANCHOR VIEWPORT
$viewport-s: 24rem !default;
$viewport-m: 32rem !default;
$viewport-l: 48rem !default;
$viewport-xl: 64rem !default;
$viewport-xxl: 90rem !default;


// ANCHOR MEASURES 
$measure--sm: 48rem; 
$measure--md: 64rem; 
$measure--lg: 90rem; 
$measure--xl: 111rem; 


// ANCHOR FONT-STYLES 

$font-size: 20px !default;
$font-weight: 300 !default;
$font-color: rgb(52, 89, 122) !default;

$font-family--1: "Antenna Condensed", sans-serif !default;
%font-family--1-extras {
  text-transform: none;
  font-weight: 300;
}

$font-family--2: "Roboto Condensed", sans-serif !default;
%font-family--2-extras {
  text-transform: none;
}

$headline-1--min: 32px !default;
$headline-1--max: 43px !default;
$headline-1--line-height: 1.093em !default;
$headline-1--letter-spacing: 0px !default;

$headline-2--min: 28px !default;
$headline-2--max: 40px !default;
$headline-2--line-height: 1.212em !default;
$headline-2--letter-spacing: 0 !default;

$headline-3--min: 20px !default;
$headline-3--max: 28px !default;
$headline-3--line-height: 1.179em !default;
$headline-3--letter-spacing: 0 !default;

$headline-4--min: 18px !default;
$headline-4--max: 23px !default;
$headline-4--line-height: 1.2em !default;
$headline-4--letter-spacing: 0px !default;

$headline-5--min: 17px !default;
$headline-5--max: 19px !default;
$headline-5--line-height: 1.2em !default;
$headline-5--letter-spacing: 0px !default;

$headline-6--min: 14px !default;
$headline-6--max: 14px !default;
$headline-6--line-height: 1.2em !default;
$headline-6--letter-spacing: 0px !default;

$sub-1--min: 20px !default;
$sub-1--max: 25px !default;
$sub-1--line-height: 1.120em !default;
$sub-1--letter-spacing: 0px !default;

$sub-2--min: 15px !default;
$sub-2--max: 15px !default;
$sub-2--line-height: 1.333em !default;
$sub-2--letter-spacing: 0px !default;

$body-1--min: 16px !default;
$body-1--max: 20px !default;
$body-1--line-height: 1.400em !default;
$body-1--letter-spacing: 0px !default;

$body-2--min: 16px !default;
$body-2--max: 17px !default;
$body-2--line-height: 1.235em !default;
$body-2--letter-spacing: 0px !default;

$caption--min: 12px !default; 
$caption--max: 14px !default;
$caption--line-height: 1.235em !default;
$caption--letter-spacing: 0px !default;

$font-button--min: 18px !default; // Final Height 50px
$font-button--max: 18px !default;
$font-button--letter-spacing: 0px !default;

$font-button-medium--min: 15px !default; // Final Height 40px
$font-button-medium--max: 15px !default;
$font-button-medium--letter-spacing: 0px !default;

$font-button-small--min: 12px !default; // Final Height 27px
$font-button-small--max: 12px !default;
$font-button-small--letter-spacing: 0px !default;


// ANCHOR MELDUNGEN

$color--sucess: rgb(215, 237, 215) !default;
$color--warning: rgb(247, 241, 205) !default;
$color--error: rgb(244, 207, 205) !default;
$color--notice: rgb(213, 215, 217) !default;

$font-color--sucess: rgb(72, 206, 54) !default;
$font-color--warning: rgb(219, 190, 1) !default;
$font-color--error: rgb(161, 1, 1) !default;
$font-color--notice: rgb(95, 100, 104) !default;

$border--sucess: 1px solid rgb(159, 179, 159) !default;
$border--warning: 1px solid rgb(185, 180, 152) !default;
$border--error: 1px solid  rgb(183, 153, 151) !default;
$border--notice: 1px solid rgb(172, 175, 178) !default;


$padding-notification: 2rem !default;
$margin-notification: 0 auto !default;
$borderradius-notification: 5px !default;


// ANCHOR COLORS

$color-1: rgb(87, 87, 86) !default;
$color-1--l2: rgb(231, 238, 246) !default;
$color-1--l1: rgb(202, 218, 236) !default;
$color-1--d1: rgb(103, 151, 193) !default; //z.B. Label erfolgsstory
$color-1--d2: none !default;

$color-2: rgb(0, 43, 79) !default;
$color-2--l2: rgb(232, 236, 239) !default;
$color-2--l1: rgb(41, 77, 107) !default; // Schriftfarbe
$color-2--d1: none !default;
$color-2--d2: none !default;

$color-3:  rgb(103, 151, 193) !default;
$color-3--l2: none !default;
$color-3--l1: none !default;
$color-3--d1: none !default;
$color-3--d2: none !default;

$color-4: none !default;
$color-4--l2: none !default;
$color-4--l1: none !default;
$color-4--d1: none !default;
$color-4--d2: none !default;

$black: rgb(0, 0, 0) !default;
$white: rgb(255, 255, 255) !default;

$color-white: $white !default;
$color-0--l3: rgb(249, 249, 249) !default;
$color-0--l2: rgb(237, 237, 237) !default;
$color-0--l1: rgb(224, 224, 224) !default;
$color-0: rgb(204, 204, 204) !default;
$color-0--d1: rgb(171, 171, 171) !default;
$color-0--d2: rgb(128, 128, 128) !default;
$color-0--d3: rgb(71, 71, 71) !default;
$color-black: $black !default;


// Font Colors
// $font-color-0: rgb(52, 89, 122); 
$font-color-0: rgb(87, 87, 86) !important;
$font-color-1: #A02A22 !important;
//$font-color-2: rgb(120, 70, 110);
//$font-color-3: rgb(128,128,128);
//$font-color-4: rgb(251, 227, 232);
//$font-color-5: rgb(204,191,131); 

$font-color-active: $font-color;
$font-color-active--hvr: $color-1;

$font-color-black: rgb(0,0,0);
$font-color-white: rgb(255,255,255);

// Links, Buttons
$color-active: $font-color;
$color-active--hvr: $color-1;


// ANCHOR VERLÄUFE

//$grad-1: linear-gradient(0deg, $color-1 33%, $color-1--l2 100%) !default;
//$grad-2: linear-gradient(0deg, $color-2 33%, $color-2--l2 100%) !default;
//$grad-3: linear-gradient(0deg, $color-3 33%, $color-3--l2 100%) !default;
//$grad-4: linear-gradient(0deg, $color-4 33%, $color-4--l2 100%) !default;
//$grad-5: linear-gradient(0deg, $color-1--d2 33%, $color-1 100%) !default;
//$grad-6: linear-gradient(0deg, $color-2--d2 33%, $color-2 100%) !default;
//$grad-7: linear-gradient(0deg, $color-3--d2 33%, $color-3 100%) !default;
//$grad-8: linear-gradient(0deg, $color-4--d2 33%, $color-4 100%) !default;


// ANCHOR TABS

$tab-default-padding: 0.3em 1em !default;

$tab-solid-rounded--border-radius: 0 !default;
$tab-pill--border-radius: 50px !default;

$tab-solid--fill: transparent !default;
$tab-solid--borderheight: 8px !default;

$tabcontent--fill: $white !default;

$tab-cube--height: 120px !default;
@function calc-tab-cube--halfheight($num, $neg: false) {
  @if $neg {
    @return $num / 2 * -1;
  }@else {
    @return $num / 2;
  }
}

$tabcontent-bordercolor-0: $color-0--l2 !default;
$tabcontent-bordercolor-1: $white !default;
$tabcontent-bordercolor-2: $color-2--l2 !default;
$tabcontent-bordercolor-3: $color-3--l2 !default;
$tabcontent-bordercolor-4: $color-4--l2 !default;
$tabcontent-bordercolor-5: $color-4--l2 !default;

$tabcolor-0--background: $color-0--l2 !default;
$tabcolor-1--background: $color-1--l1 !default;
$tabcolor-2--background: $color-2--l2 !default;
$tabcolor-3--background: $color-3--l2 !default;
$tabcolor-4--background: $color-4--l2 !default;
$tabcolor-5--background: $color-4--l2 !default;

$tabcolor-0--background-hover: $color-0--l1 !default;
$tabcolor-1--background-hover: $color-1--d1 !default;
$tabcolor-2--background-hover: $color-2--l1 !default;
$tabcolor-3--background-hover: $color-3--l1 !default;
$tabcolor-4--background-hover: $color-4--l1 !default;
$tabcolor-5--background-hover: $color-4--l1 !default;


// ANCHOR BUTTONS

//$button-default-padding: .75em 1em !default;
$button-default-padding: 1em 2.125em !default; //NOTE Changed

$button-border-radius: 0 !default;

$button-fill: $color-active !default;
$button-fill--hover: $color-active--hvr !default;
$button-transition: 0.2s ease-in-out !default;

$button-solid--color: $white !default;
$button-solid--color-hover: $white !default;

$button-outline--fill: transparent !default;
$button-outline--color: $black !default;
$button-outline--color-hover: $white !default;
$button-outline--border: 2px solid $color-active !default;
$button-outline--border-hover: 2px solid transparent !default;
$button-outline--hover-fill: $color-active--hvr !default; 

$button-pill--border-radius: 50px !default;

$button-bubble-arrow-size: 10px !default;
// Button Icons
$button-icon-default-hover: false !default;
$button-icon-default-animation: ".hvr-forward" !default;
$button-icon-default-direction: "left" !default;


// ANCHOR PADDINGS | MARGINS

$default-p--i: 1.875rem !default;
$default-p--o: 1.875rem !default;

$p1: 1rem !default;
$p1--i: $default-p--i !default;
$p1--o: $default-p--o !default;

$p2: 2rem !default;
$p2--i: $default-p--i*1.5 !default;
$p2--o: $default-p--o*1.5 !default;

$p3: 3rem !default;
$p3--i: $default-p--i*2 !default;
$p3--o: $default-p--o*2 !default;

$p4: 4rem !default;
$p4--i: $default-p--i*2.5 !default;
$p4--o: $default-p--o*2.5 !default;

$m1: 1rem !default;
$m2: 2rem !default;
$m3: 3rem !default;
$m4: 4rem !default;


// ANCHOR TRENNER