// out: false, main: ../style.scss
///////////////////////////////////////
//////////////// Tabs ////////////////
/////////////////////////////////////
.tablist {
  display: flex; 
  flex-wrap: wrap; 
  margin: 0; 
  flex-direction: column;
  &.tabs--left {
    // Hier noch vorsehen, die Tabs auf der linken Seite zu platzieren
  }
  label { 
    cursor: pointer; 
    width: 100%; 
    margin-bottom: 1rem; 
    -webkit-tap-highlight-color: rgba(0,0,0,0); 
    &:focus {
      outline: none;
    }
  }
  .tabcontent { 
    display: none; 
    order: inherit; 
    width: 100%; 
    flex-grow: 1; 
    max-height: 0px; 
    padding: 0 1rem 1rem 1rem; 
    transition: all .5s linear; 
  }
  input[type="radio"] { 
    display: none; 
    &:checked + label { 
      margin-bottom: 0; 
    }
    &:checked + label + .tabcontent { 
      display: block; 
      max-height: 500px; 
      transition: all .5s linear; 
    }
  }
}

.tabs--solid-border {
  label { 
    position: relative; 
    &:after { 
      content: '';
      display: block;
      border-bottom: $tab-solid--borderheight solid $tab-solid--fill;
      width: 0; 
      bottom: 0; 
      position: absolute; 
      left: 0; 
      transition: all .3s ease; 
      z-index:10; 
    }
    &.reverse:after {
      top: 0; 
      border-bottom: 0; 
      border-top: $tab-solid--borderheight solid $tab-solid--fill;
    }
    &.leftborder:after {
      border-bottom: 0; 
      border-left: $tab-solid--borderheight solid $tab-solid--fill; 
      height: 0;
      width: auto;
    }
    &.rounded {
      border-top-left-radius: 20px; 
      border-top-right-radius: 20px;
    }
  }
  input[type="radio"]:checked + label { 
    background-color: $tab-solid--fill;
    &:after { 
      width: 100%; 
      transition: width .4s ease;
    }
    &.reverse:after { 
      border-top-width: $tab-solid--borderheight;
      border-top-style: solid;
    }    
    &.leftborder:after { 
      border-bottom: 0; 
      border-left-width: $tab-solid--borderheight;
      border-left-style: solid;
      height: 100%;
      transition: height .4s ease;
    }
  }
  
  &.tabcolor-0 {
    input[type="radio"]:checked + label {
      &:after { border-color: $color-0; }
      &.colored { background-color: $tabcolor-0--background; }
    } 
  }
  &.tabcolor-1 {
    input[type="radio"]:checked + label {
      &:after { border-color: $color-1; }
      &.colored { background-color: $tabcolor-1--background; }
    } 
  }
  &.tabcolor-2 {
    input[type="radio"]:checked + label {
      &:after { border-color: $color-2; }
      &.colored { background-color: $tabcolor-2--background; }
    } 
  }
  &.tabcolor-3 {
    input[type="radio"]:checked + label {
      &:after { border-color: $color-3; }
      &.colored { background-color: $tabcolor-3--background; }
    } 
  }
  &.tabcolor-4 {
    input[type="radio"]:checked + label {
      &:after { border-color: $color-4; }
      &.colored { background-color: $tabcolor-4--background; }
    } 
  }
}

.tabs--solid {
  label { 
    position: relative; 
    top: 0px; 
    transition: all 0.25s;
    &:hover { 
      top: -0.25rem; 
      transition: top 0.25s; 
    }
  }
  input[type="radio"]:checked + label {
    background-color: $white;
    &:hover { 
      top:0; 
      transition: none; 
    }
  }
  &.tabcolor-0 {
    label { 
      background-color: $tabcolor-0--background;
    } 
  }
  &.tabcolor-1 {
    label { 
      background-color: $tabcolor-1--background;
    }
  }
  &.tabcolor-2 {
    label { 
      background-color: $tabcolor-2--background;
    }
  }
  &.tabcolor-3 {
    label { 
      background-color: $tabcolor-3--background;
    }
  }
  &.tabcolor-4 {
    label { 
      background-color: $tabcolor-4--background;
    }
  }
  &.tabcolor-5 {
    label { 
      background-color: $tabcolor-5--background;
    }
  }
}

.tabs--pill {
  label { 
    border-radius: 10px; 
    z-index: 20; 
    margin-bottom: 3px; 
    transition: all .5s ease; 
    &:hover {
      border-radius: 20px; 
      transition: all .5s ease;
    }
  }
  .tabcontent { 
    margin-top: -1px; 
    z-index: 10; 
  }
  input[type="radio"]:checked + label { 
    background-color: $white; 
    margin-bottom: 0; 
  }
  &.tabcolor-0 { 
    label { 
      background-color: $tabcolor-0--background;
      &:hover {
        background-color: $tabcolor-0--background-hover;
      }
    } 
  }
  &.tabcolor-1 { 
    label {
      background-color: $tabcolor-1--background;
      &:hover {
        background-color: $tabcolor-1--background-hover;
      }
    }
  }
  &.tabcolor-2 { 
    label {
      background-color: $tabcolor-2--background; 
      &:hover {
        background-color: $tabcolor-2--background-hover;
      }
    } 
  }
  &.tabcolor-3 { 
    label {
      background-color: $tabcolor-3--background; 
      &:hover {
        background-color: $tabcolor-3--background-hover;
      }
    } 
  }
  &.tabcolor-4 { 
    label {
      background-color: $tabcolor-4--background; 
      &:hover {
        background-color: $tabcolor-4--background-hover;
      }
    } 
  }
  &.tabcolor-5 { 
    label {
      background-color: $tabcolor-5--background; 
      &:hover {
        background-color: $tabcolor-5--background-hover;
      }  
    } 
  }
} 

.tabs--cube {
  .cube { margin-bottom: 1rem; }
  .cube .tabcontent h3 { margin-top: -1px; }

  #cube-tab1 { 
    order: 1; 
    + label { 
      order: 2; 
    }
    &:checked ~ .cube { 
      order: 3; 
      position: relative; 
      .tabcontent:nth-child(1) { 
        display: block; 
        max-height: 500px; 
      }
    }
  }
  #cube-tab2 { 
    order: 4; 
    + label { 
      order: 5; 
    }
    &:checked ~ .cube { 
      order: 6; 
      position: relative;
      .tabcontent:nth-child(1) { 
        display: block; 
        max-height: 500px; 
      }
    }
  }
  #cube-tab3 { 
    order: 7; 
    + label { 
      order: 8; 
    }
    &:checked ~ .cube { 
      order: 9; 
      position: relative; 
      .tabcontent:nth-child(1) { 
        display: block; 
        max-height: 500px; 
      }
    }
  }
}

@media (min-width: $viewport-l) {
  .tablist {
    flex-direction: row;
    &.tabs--left {
      // Hier noch vorsehen, die Tabs auf der linken Seite zu platzieren
    }
    label { 
      order: 1; 
      width: auto; 
      margin-bottom: 0;
    }
    .tabcontent { 
      transition: none; 
      padding: 1rem; 
      order: 99; 
      &:not(.cube-tabcontent) {
      border: 1px solid $white;
      background: $tabcontent--fill;
      }
    }
    &.tabcolor-0 { 
      .tabcontent { border-color: $tabcontent-bordercolor-0; }
    }
    &.tabcolor-1 { 
      .tabcontent { border-color: $tabcontent-bordercolor-1; }
    }
    &.tabcolor-2 { 
      .tabcontent { border-color: $tabcontent-bordercolor-2; }
    }
    &.tabcolor-3 { 
      .tabcontent { border-color: $tabcontent-bordercolor-3; }
    }
    &.tabcolor-4 { 
      .tabcontent { border-color: $tabcontent-bordercolor-4; }
    }
    &.tabcolor-5 { 
      .tabcontent { border-color: $tabcontent-bordercolor-5; }
    }
  }
  .tabs--solid {
    label { 
      border-right: 1px solid $tabcontent--fill;
      &:hover { 
        top: -0.25rem; 
        transition: top 0.25s; 
      }
    }
    .tabcontent { 
      margin-top: -1px; 
      z-index: 10; 
    }
    &.tabcolor-0 { 
      label { border-top: 1px solid $tabcolor-0--background; }
      input[type="radio"]:checked + label { border: 1px solid $tabcolor-0--background; }
    }
    &.tabcolor-1 { 
      label { border-top: 1px solid $tabcolor-1--background; }
      input[type="radio"]:checked + label { border: 1px solid $tabcolor-1--background; }
    }
    &.tabcolor-2 { 
      label { border-top: 1px solid $tabcolor-2--background; }
      input[type="radio"]:checked + label { border: 1px solid $tabcolor-2--background; }
    }
    &.tabcolor-3 { 
      label { border-top: 1px solid $tabcolor-3--background; }
      input[type="radio"]:checked + label { border: 1px solid $tabcolor-3--background; }
    }
    &.tabcolor-4 { 
      label { border-top: 1px solid $tabcolor-4--background; }
      input[type="radio"]:checked + label { border: 1px solid $tabcolor-4--background; }
    }
    &.tabcolor-5 { 
      label { border-top: 1px solid $tabcolor-5--background; }
      input[type="radio"]:checked + label { border: 1px solid $tabcolor-5--background; }
    }
    input[type="radio"]:checked + label {
      border-bottom:none;
    }
  }

  .tabs--pill {
    label {
      margin-right: 2px; 
      margin-bottom: 3px;
    }

    &.tabcolor-0 { 
      input[type="radio"]:checked + label, .tabcontent {
        border: 1px solid $tabcolor-0--background;
      }
    }
    &.tabcolor-1 { 
      input[type="radio"]:checked + label, .tabcontent {
        border: 1px solid $tabcolor-1--background;
      }
      }
    &.tabcolor-2 { 
      input[type="radio"]:checked + label, .tabcontent {
        border: 1px solid $tabcolor-2--background;
      }
      }
    &.tabcolor-3 { 
      input[type="radio"]:checked + label, .tabcontent {
        border: 1px solid $tabcolor-3--background;
      }
      }
    &.tabcolor-4 { 
      input[type="radio"]:checked + label, .tabcontent {
        border: 1px solid $tabcolor-4--background;
      }
      }
    &.tabcolor-5 { 
      input[type="radio"]:checked + label, .tabcontent {
        border: 1px solid $tabcolor-5--background;
      }
    }
    input[type="radio"]:checked + label {
      border-bottom: none !important; 
      border-bottom-left-radius: 0; 
      border-bottom-right-radius: 0;
    }
  }

  .tabs--cube { 
    perspective: 80em; 
    backface-visibility: hidden;
    label, input { 
      order: unset !important; 
    }
    label  { 
      border: 1px solid $tabcontent--fill;
      border-left:0;
    }
    .cube { 
      position: relative; 
      margin: 2px 7px; 
      width: 100%; 
      height: $tab-cube--height; 
      transform-origin: 0 60px;  /* fallback for browsers without support for calc() */
      transform-origin: 0 calc-tab-cube--halfheight($tab-cube--height); /* Hälfte der Höhe */
      transform-style: preserve-3d; 
      transition: transform 0.5s ease-in;
      will-change: auto;
      .tabcontent  { 
        border: none; 
        order: unset; 
        width: 100%; 
        max-height: none; 
        height: $tab-cube--height; 
        position: absolute; 
        display: block;
        h3 {
          margin-top: inherit;
        } 
        &:nth-child(1) { 
          transform: rotateX(-270deg) translateY(calc-tab-cube--halfheight($tab-cube--height,neg)); 
          transform-origin: top left; 
        }
        &:nth-child(2) { 
          transform: translateZ(calc-tab-cube--halfheight($tab-cube--height));
        }
        &:nth-child(3) { 
          transform: rotateX(-90deg) translateY(calc-tab-cube--halfheight($tab-cube--height)); 
          transform-origin: bottom center;
        }
      }
    }
  }
  #cube-tab1:checked ~ .cube { 
    transform: rotateX(-90deg); 
    order: unset; 
  }
  #cube-tab2:checked ~ .cube { 
    transform: rotateX(0deg); 
    order: unset; 
  }
  #cube-tab3:checked ~ .cube { 
    transform: rotateX(90deg); 
    order: unset; 
  }
}