
/******************************************************************/

@font-face {
    font-family: "Klavika";
    src: url("../font/Klavika/Klavika-Bold.eot");
    src: local("Klavika Bold"), local("Klavika-Bold"),
        url("../font/Klavika/Klavika-Bold.eot?#iefix") format("embedded-opentype"),
        url("../font/Klavika/Klavika-Bold.woff2") format("woff2"),
        url("../font/Klavika/Klavika-Bold.woff") format("woff"),
        url("../font/Klavika/Klavika-Bold.ttf") format("truetype"),
        url("../font/Klavika/Klavika-Bold.svg#Klavika-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Klavika";
    src: url("../font/Klavika/Klavika-Regular.eot");
    src: local("Klavika Regular"), local("Klavika-Regular"),
        url("../font/Klavika/Klavika-Regular.eot?#iefix") format("embedded-opentype"),
        url("../font/Klavika/Klavika-Regular.woff2") format("woff2"),
        url("../font/Klavika/Klavika-Regular.woff") format("woff"),
        url("../font/Klavika/Klavika-Regular.ttf") format("truetype"),
        url("../font/Klavika/Klavika-Regular.svg#Klavika-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/******************************************************************/

i.icon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    top: 0.3rem;
}

i.icon.icon-arrow-left {
    background-image: url("../img/icon/arrow-left.svg");
}

i.icon.icon-arrow-right {
    background-image: url("../img/icon/arrow-right.svg");
}

i.icon.icon-chevron-left {
    background-image: url("../img/icon/chevron-left.svg");
}

i.icon.icon-chevron-right {
    background-image: url("../img/icon/chevron-right.svg");
}

i.icon.icon-home {
    background-image: url("../img/icon/home.svg");
}

i.icon.icon-mail {
    background-image: url("../img/icon/mail.svg");
}

i.icon.icon-map-pin {
    background-image: url("../img/icon/map-pin.svg");
}

i.icon.icon-phone {
    background-image: url("../img/icon/phone.svg");
}

i.icon.icon-play-circle {
    background-image: url("../img/icon/play-circle.svg");
}

i.icon.icon-play {
    background-image: url("../img/icon/play.svg");
}

i.icon.icon-video {
    background-image: url("../img/icon/video.svg");
}

.icon-margin-right {
    margin-right: 0.5rem;
}

.icon-margin-left {
    margin-left: 0.5rem;
}

/******************************************************************/

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea,
button {
    font-family: "Klavika";
}
#top{
    margin-top: 0;
}

body.font--body-1 {
    margin-bottom: 0;
}

::selection {
    background-color: #A02A22;
    color: #fff;
}

// .brand-font-color {
//     color: #A02A22;
// }

.brand-background-color {
    background-color: #A02A22;
}

/******************************************************************/

.float-right {
    float: right;
}

.font-weight-normal {
    font-weight: normal;
}
.font-weight-bold {
    font-weight: bold;
}

.rounded {
    border-radius: 0.5rem;
}

.display-block {
    display: block;
}

.hidden {
    display: none;
}

.button-custom {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: rgba(255,255,255,1);
    border-radius: 0.5rem;
    background-color: rgba(167, 36, 44, 1);
    transition: color 0.2s ease, background-color 0.2s ease;
}

.button-custom-light {
    background-color: rgba(196, 196, 196, 1);
    padding: 0.5rem 0;
    box-shadow: 0 0 0 0 transparent !important;
}

.button-custom > .icon-button {
    margin-left: 0.5rem;
    font-size: 2rem;
    display: inline-block;
    position: relative;
    top: 0.18rem;
}

.button-custom:hover {
    background: rgba(255,255,255,1);
    color: rgba(167, 36, 44, 1);
    box-shadow: 0 0 0 2px rgba(167, 36, 44, 1) inset;
}

#footer-button-pkw{
    margin-top: 1rem;
}
.button-footer-anfahrt{
        width: 65%;
        text-align: center;
    }
@media (max-width: 50rem) {
    .button-custom{
        padding: 0.5rem 0.8rem;
    }
}
@media (min-width: 32rem) {
    .button-footer-anfahrt{
        width: 40%;
        text-align: center;
    }
}
@media (min-width: 48rem) {
    #footer-button-pkw{
        margin-top: 0rem;
    }
    .button-footer-anfahrt{
        width: 100%;
        text-align: center;
    }
}

@media (min-width: 64rem) {
    .button-custom{
        padding: 0.5rem 2rem;
    }
}

/******************************************************************/

img.logo {
    width: 150px;
    height: auto;
}

#main-navigation {
    z-index: 998;
    max-height: 100vh; 
}
@media (max-width: 1023px) {
    #main-navigation {
        top: 5rem;
        overflow: auto;
    }
}


#main-navigation > ul > li > a {
    font-size: 1.4rem !important;
    text-align: center !important;
    text-decoration: none;
}

#main-navigation a:before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

#main-navigation .lvl-1.entry .nav-link.has-subnavi::after {
    display: none;
    background: unset !important;
}

#main-navigation .lvl-1.entry .subnavi {
    background: rgba(255,255,255,0.85);
    border-radius: 0 0 0.5rem 0.5rem;
}

#main-navigation .lvl-1.entry .nav-link {
    padding: .375rem .75rem;
}

a {
    text-decoration: none !important;
}
#main-navigation a{
    color: rgb(87, 87, 86);
}
#main-navigation a:hover,
#main-navigation .lvl-1.entry .nav-link:hover {
    font-weight: bold;
    color: #A02A22 !important;
}

#footer-wrapper {
    background-color: #f7faf1;
    // border-radius: 68% 32% 0% 0% / 14% 7% 0% 0%;
}
// .shape-footer{
//     background-color: #f7faf1;
//     height: 7rem;
//     border-radius: 68% 32% 0% 0% / 100% 40% 0% 0%;
// }
#footer-shape{
    display: block;
}

@media (min-width: 50rem) {

    

    .footer-link {
        margin-left: -33px;
    }
    
    .footer-link > i {
        margin-right: 14px;
    }

    .footer-bottom {
        text-align: right;
    }

}

@media (min-width: 64rem) {
    #footer img.logo {
        margin-left: -63px;
    }
}

/******************************************************************/

.background-image-maps {
    width: 100%;
    height: 50rem;
    background-image: url(../img/background/maps.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
}

#map{
    height: 50rem;
}

/******************************************************************/

.dashed {
    height: 1px;
    width: 100%;
    background-image: url("../img/background/dash.png");
    background-repeat: repeat-x;
    overflow: hidden;
}

/******************************************************************/

#breadcrumb li:first-child a:before {
    display: none;
}

#breadcrumb a:before {
    content: "›";
    background: none !important;
    margin-top: -0.1rem;
    height: auto;
}

/******************************************************************/

.list-links > li > a {
    display: inline-block;
}

.list-links > li > a:hover {
    font-weight: bold;
}

/******************************************************************/

.teaser-box {
    width: 16rem;
    text-align: center;
    margin: 2rem auto 2rem;
}
.teaser-box-bewerbung {
    width: 15rem;
    text-align: center;
    padding: 0 1rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10%;
    z-index: 1;
}
.text-box-header{
    text-align: center;
    margin: 2rem auto 2rem;
    max-width: 50rem;
}
.text-box-unser-team{
    text-align: center;
    margin: 2rem auto 2rem;
    max-width: 50rem;
}

@media (min-width: 70rem) {

    .teaser-box {
        width: 19rem;
        text-align: center;
        padding: 1rem;
        position: absolute;
        right: 4rem;
        bottom: 2rem;
        z-index: 1;
    }
    .teaser-box-bewerbung {
        width: 15rem;
        text-align: center;
        padding: 1rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 10%;
        z-index: 1;
    }
    .text-box-header{max-width:50rem;color:#1a171b;}
    .text-box-header{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);color:#fff;}
    .text-box-header{top:auto;bottom:30px;left:32%;z-index: 999;text-align: left;}

    .text-box-unser-team{max-width:50rem;color:#1a171b;}
    .text-box-unser-team{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);color:#fff;}
    .text-box-unser-team{top:auto;bottom:-60px;left:43%;z-index: 999;text-align: left;}

}

/******************************************************************/

img.team-portrait {
    width: 200px;
    height: auto;
    border-radius: 100px;
    box-shadow: inset 6px 6px 6px 0 rgba(0, 0, 0, 0.3);
}

/******************************************************************/

.headerswiper-container #headerswiper1,
.headerswiper-container #headerswiper2 {
    overflow: hidden
}

.headerswiper-container #headerswiper1 .swiper-slide,
.headerswiper-container #headerswiper2 .swiper-slide {
    position: relative
}

.headerswiper-container #headerswiper1 .swiper-slide .text,
.headerswiper-container #headerswiper2 .swiper-slide .text {
    max-width: 50rem;
    color: #1a171b
}

.headerswiper-container #headerswiper1 .swiper-slide .objectfit-img,
.headerswiper-container #headerswiper2 .swiper-slide .objectfit-img {
    -o-object-fit: cover;
    object-fit: cover;
    height: auto;
    max-height: 253px
}

#headerswiper1 .swiper-slide .text,
#headerswiper2 .swiper-slide .text {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff
}

.headerswiper-container #headerswiper1 .swiper-slide .objectfit-img,
.headerswiper-container #headerswiper2 .swiper-slide .objectfit-img {
    max-height: 495px
}

.headerswiper-container #headerswiper1,
.headerswiper-container #headerswiper2 {
    height: 45vw;
    max-height: 1024px
}

.headerswiper-container #headerswiper1 .swiper-slide,
.headerswiper-container #headerswiper2 .swiper-slide {
    height: 50vw;
    max-height: 100%
}

.headerswiper-container #headerswiper1 .swiper-slide.swiper-slide-active .text .inner, 
.headerswiper-container #headerswiper2 .swiper-slide.swiper-slide-active .text .inner {
    opacity: 0;
    -webkit-animation-name: slideInAnim;
    animation-name: slideInAnim;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.headerswiper-container #headerswiper1 .swiper-slide .objectfit-img,
.headerswiper-container #headerswiper2 .swiper-slide .objectfit-img {
    height: 100%;
    max-height: none
}

/******************************************************************/

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    display: none;
}

.headerswiper-container .swiper-button-next,
.slider-v1 .swiper-button-next {
    background-image: url("../img/icon/chevron-right.svg");
}

.headerswiper-container .swiper-button-prev,
.slider-v1 .swiper-button-prev {
    background-image: url("../img/icon/chevron-left.svg");
}

#headerswiper1 .swiper-slide .text,
#headerswiper2 .swiper-slide .text {
    top: auto;
    bottom: 0;
    left: 35%;
}

#headerswiper1 .swiper-slide{
    border-radius: 0% 0% 32% 68% / 0% 0% 14% 7%;
}


.praxis-slide a {
    position: relative;
    display: block;
}

.praxis-slide a:before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255,255,255,0);
    transition: background-color 0.2s ease;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.praxis-slide a:hover:before {
    background-color: rgba(255,255,255,0.6);
}

.slider-gradient-sides:before,
.slider-gradient-sides:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    width: 15%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}

.slider-gradient-sides:after {
    left: auto;
    right: 0;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

.slider-gradient-sides.with-left-gray:before {
    background: linear-gradient(90deg, rgba(224, 224, 224, 1) 0%, rgba(128,128,128,0) 100%);
}

.slider-gradient-bottom {
    width: 100%;
    left: 0;
    bottom: 0;
    height: 60%;
    position: absolute;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}

.slider-left-gray {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    width: 100rem;
    margin-left: -100rem;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(224, 224, 224, 1);
}

.swiper-pagination .swiper-pagination-bullet {
    background: #d9aaa6;
}
.swiper-pagination .swiper-pagination-bullet-active {
    background: #A02A22;
}

.swiper-pagination-bullet {
    width: 1rem;
    height: 1rem;
}

/******************************************************************/

form input {
    box-shadow: 0 0 0 1px #777;
}

/******************************************************************/
#hamburger{
    position: absolute;
    top: 2rem;
    right: 1rem;
    z-index: 999;
}
@media (min-width: 64rem) {
    #hamburger{
        display: none;
    }
}
.is-open{
    display: block !important;
    border-bottom: 1px solid #A02A22;
}

.text-box-unser-team-mobile{
    display: block;
    @media (min-width: 70rem) {
        display: none;
    }
}

#nav-home-icon{
    height: 1.5rem;
    width: 1.5rem;
    top: -2.5rem;
    filter: invert(16%) sepia(50%) saturate(4082%) hue-rotate(349deg) brightness(100%) contrast(87%);
}
.visible-desktop{
    display: none;
    @media (min-width: 70rem) {
        display: block;
    }
}


//////////////////////////////////////////////////////
#header{
    position: sticky;
    top: 0;
    z-index: 99999;
    background-color: #fff;
    border-bottom: 1px solid #A02A22;
    padding: 1rem 0;
}

#header-container{
    height: 105px;
    transition: height .5s;
    #hamburger{
        transition: top .5s;
    }
    .logo-container{
        .logo{
            transition: width .5s;
        }
    }
    #main-navigation{
        transition: margin-top .5s;
    }
}
.nav-scrolled{
    height: 50px !important;
    .has-subnavi{
        .subnavi{
            top: 4rem;
            padding-top: 2rem !important;
        }
    }
    #hamburger{
        transition: top .5s;
        top: 1rem;
    }
    .logo-container{
        margin-top: 0;
        .logo{
            width: 100px;
            transition: width .5s;
        }
    }
    #main-navigation{
        transition: margin-top .5s;
        margin-top: 0;
    }
}