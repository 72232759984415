// out: false, main: ../style.scss

$masonry-grd-default: 10rem;
$masonry-grd-sm: 15rem;
$masonry-grd-md: 17rem;
$masonry-grd-lg: 23rem;

$masonry-columns-sm: 1;
$masonry-columns-md: 2;
$masonry-columns-lg: 4;

$masonry-grd-spacing: 0.5rem;
$masonry-overlay-mode: true;
$masonry-grd-bg: $black;

.masonry {
  column-count: $masonry-columns-sm;
  column-gap: 0;
  counter-reset: item-counter;
  @media screen and (min-width: $viewport-m) { 
    column-count: $masonry-columns-md;
  }
  @media screen and (min-width: $viewport-xl) {  
    column-count: $masonry-columns-lg;
  }
}

.item {
  position: relative;
  break-inside: avoid;
  padding: $masonry-grd-spacing;
  z-index: 1;
  &-content {
    position: relative;
    height: $masonry-grd-default;
    background-color: $masonry-grd-bg;
    @if ($masonry-overlay-mode == true){
      cursor: pointer;
      img {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba($black, 0.5);
        z-index: 2;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      &::after {
        content: ""; 
        background-image: url("../images/zoom-in.svg");
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%);
        z-index: 3;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto;
        height: 40px;
        width: 43px;
      }
      &:hover {
        &::before {
          opacity: 1;
          box-shadow: inset 0 0 0 2px white;
          border: 6px solid transparent;
        }
        &::after {
          transform: translate(-50%, -50%);
          opacity: 1;
          transition: all 0.3s ease-in-out;
        }
        img {
          transform: scale(1);
        }
      }
    }
    &--sm {
      height: $masonry-grd-sm;
    }
    &--md {
      height: $masonry-grd-md;
    }
    &--lg {
      height: $masonry-grd-lg;
    }
  } 
}