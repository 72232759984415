@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes tada {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: scale(7);
  }

  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: scale(1);
  }

  55% {
    animation-timing-function: ease-in;
    transform: scale(1.5);
  }

  72% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  81% {
    animation-timing-function: ease-in;
    transform: scale(1.24);
  }

  89% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  95% {
    animation-timing-function: ease-in;
    transform: scale(1.04);
  }

  100% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}

@keyframes jelly {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(.75, 1.25, 1);
  }

  40% {
    transform: scale3d(1.25, .75, 1);
  }

  50% {
    transform: scale3d(.85, 1.15, 1);
  }

  65% {
    transform: scale3d(1.05, .95, 1);
  }

  75% {
    transform: scale3d(.95, 1.05, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rotate {
  0% {
    opacity: 0;
    transform: translateZ(-200px) rotate(-45deg);
  }

  100% {
    opacity: 1;
    transform: translateZ(0) rotate(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px transparentize($pretty--color-default, 0);
  }

  100% {
    box-shadow: 0px 0px 0px 1.5em transparentize($pretty--color-default, 1);
  }
}