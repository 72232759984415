// out: false, main: ../style.scss
///////////////////////////////////////
//////////// Footer v1 ////////////////
///////////////////////////////////////
.footer-v1 {
  background-color: $color-0--d3;
  color: $color-0--l2;
  a {
    color: $color-0--l2;
    transition: color 0.4s ease;
    &:hover, &:focus {
      color: darken($color-0--l2, 10%);
    }
  }
  .footer {
    &--item-1 {
      ul {
        margin-bottom: 1rem;
        padding: 0;
        li {
          display: inline-block;
          padding-right: 1rem;
        }
      }
    }
    &--item-2 {
      .inner-left {
        margin-top: 1rem;
        margin-left: 0rem;
      }
      @media(min-width: $viewport-xl){
        display: flex;
      }
      p {
        margin: 0;
      }
    }
  }
}