// out: false, main: ../style.scss
$img-tile-frame-size: 1rem;
.img-tile-outer {
  background-color: $white;
  margin: 1rem;
  &.border-frame {
    padding: $img-tile-frame-size;
  }
  .img-tile-inner {
    position: relative;
    cursor: pointer;
    height: 270px;
    .text-wrapper {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      color: $white;
      z-index: 1;
      .inner {
        .center {
          display: flex;
          justify-content: center;
          & > * {
            text-align: center;
          }
          p {
            line-height: 1.5;
            &:first-child {
              margin-top: 0;
            }
          }
          h3 {
            font-weight: bold;
          }
        }
      }
    }
    .fill {
      position: absolute;
      top: 0;
      background-color: rgba(rgb(27, 27, 27), 0.5);
      height: 100%;
      width: 100%; 
    }
  }
}