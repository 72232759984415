///////////////////////////////////////
////////// Full Width Header //////////
///////////////////////////////////////
.objectfit-header {
  position: relative;
  width: 100%; 
  height: 90vw;
  overflow: hidden;
  @media (min-width: $viewport-l){
    height: 80vh;
  }
  .measure--lg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
  .objectfit-picture {
    height: 100%; 
    width: 100%;
    @media (min-width: $viewport-l){
      height: 100%; 
      width: auto; 
    }
  }
  .objectfit-img { 
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
  }
  .header-img {
    min-width: 100%;
    display: block;
  }
  .desc-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
  } 
}