// out: false, main: ../style.scss
///////////////////////////////////////
////////////// Form V2 ////////////////
///////////////////////////////////////
$input-label-border-color-active: $black;
$std-form-font-size: 0.8rem;
$std-form-font-color: $black;
$std-form-border-color-highlight: lighten($color-0--d2, 30%);
$form-input-field-fill-color: #fff;

.form-v2 {
  background-color: $color-0--l2;
  color: $std-form-font-color;
  .form-msg {
    font-weight: bold;
    margin-top: 1rem;
    &.error {
      color: rgb(187, 22, 50);
    }
    &.success {
      color: rgb(41, 177, 70);
    }
  }
  #pflichtfeld-info {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  &#contactform {
    .select-box,
    .input__label--hoshi::before,
    .input__label--hoshi::after,
    .input__label--hoshi-color::after {
      border-bottom: 1px solid $black;
    }
    .data-protection-submit {
      .data-protection {
        position: relative;
        span {
          padding-left: 1.3rem;
          // iOS
          @supports (-webkit-overflow-scrolling: touch) {
            padding-left: 1.6rem;
          }
        }
      }
      input {
        position: absolute;
        top: 0.3rem;
        &.checkbox {
          margin-bottom: 0;
          vertical-align: middle;
        }
      }
    }
    .select-box {
      position: relative;
      padding-right: 0;
      padding-bottom: 0;
      margin-bottom: 0.5em;
      &::after {
        position: absolute;
        content: "";
        display: inline-block;
        background-image: url("../img/arrow-down-black-fallback.png");
        background-repeat: no-repeat;
        height: 12px;
        width: 12px;
        right: 0.5rem;
        bottom: 11px;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: center;
                transform-origin: center;
        -webkit-transition: -webkit-transform 0.2s ease-in-out;
        transition: -webkit-transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
        pointer-events: none;
        @supports (color: black){
          background-image: url("../img/arrow-down-black.svg");
        }
      }
      &.select-box--active {
        border-bottom: 4px solid $black;
        &::after {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
          -webkit-transform-origin: center;
                  transform-origin: center;
          -webkit-transition: -webkit-transform 0.2s ease-in-out;
          transition: -webkit-transform 0.2s ease-in-out;
          transition: transform 0.2s ease-in-out;
          transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
        }
      }
      select {
        padding: .5rem;
        padding-left: .25rem;
        background-color: $form-input-field-fill-color;
      }
    }
    .input__label--hoshi::after {
      border-bottom: 4px solid $black;
    }
  }
  .select-box {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 0;
    max-width: 95%;
    width: calc(100% - 2em);
    vertical-align: top;
    margin-bottom: 1em;
    padding: .25em;
    padding-left: 0;
    border: 0;
    border-bottom: 1px solid $std-form-border-color-highlight;
    vertical-align: top;
    font-weight: bold;
    &.anrede {
      max-width: 5rem;
    }
    &.select-box--active {
      border-bottom: 4px solid $std-form-border-color-highlight;
    }
    .select {
      border: 0;
      outline: 0;
      margin: 0;
      margin-top: 28px;
      padding: 0;
      font-weight: bold;
      font-size: $std-form-font-size;
      background: transparent;
      background-image: none;
      -webkit-appearance: none;
      &.option--selected {
        font-size: 1rem;
        line-height: 1.42;
      }
    }
  }
  .input {
  	position: relative;
  	z-index: 1;
  	display: inline-block;
  	margin: 0;
  	max-width: 100%;
  	width: calc(100% - 2em);
  	vertical-align: top;
  }

  .input__field {
  	position: relative;
  	display: block;
  	float: right;
  	padding: 0.8em;
  	width: 60%;
  	border: none;
  	border-radius: 0;
  	background: #f0f0f0;
  	font-weight: bold;
  	-webkit-appearance: none; /* for box shadows to show on iOS */
    &.textarea {
      margin-top: 2rem;
      resize: none;
    }
  }

  .input__field:focus {
  	outline: none;
  }

  .input__label {
  	display: inline-block;
  	float: right;
  	padding: 0 1em;
  	width: 40%;
  	font-weight: bold;
  	font-size: $std-form-font-size;
  	-webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  	-webkit-touch-callout: none;
  	-webkit-user-select: none;
  	-moz-user-select: none;
  	-ms-user-select: none;
  	user-select: none;
  }

  .input__label-content {
  	position: relative;
  	display: block;
  	padding: 1.6em 0;
  	width: 100%;
  }
  /* Hoshi */
  .input--hoshi {
  	overflow: hidden;
  }

  .input__field--hoshi {
  	margin-top: 1.5em;
  	padding: 0.85em 0.15em;
  	width: 100%;
  	background: $form-input-field-fill-color;
  }

  .input__label--hoshi {
  	position: absolute;
  	bottom: 0;
  	left: 0;
  	padding: 0 0.5em;
  	width: 100%;
  	height: calc(100% - 1em);
  	text-align: left;
  	pointer-events: none;
  }

  .input__label-content--hoshi {
  	position: absolute;
  }

  .input__label--hoshi::before,
  .input__label--hoshi::after {
  	content: '';
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: calc(100% - 10px);
  	border-bottom: 1px solid $std-form-border-color-highlight;
  }

  .input__label--hoshi::after {
  	margin-top: 2px;
  	border-bottom: 4px solid $std-form-border-color-highlight;
  	-webkit-transform: translate3d(-100%, 0, 0);
  	transform: translate3d(-100%, 0, 0);
  	-webkit-transition: -webkit-transform 0.3s;
  	transition: -webkit-transform 0.3s;
  	transition: transform 0.3s;
  	transition: transform 0.3s, -webkit-transform 0.3s;
  }

  .input__label--hoshi-color::after {
  	border-color: $std-form-border-color-highlight;
  }

  .input__field--hoshi:focus + .input__label--hoshi::after,
  .input--filled .input__label--hoshi::after {
  	-webkit-transform: translate3d(0, 0, 0);
  	transform: translate3d(0, 0, 0);
  }

  .input__field--hoshi:focus + .input__label--hoshi .input__label-content--hoshi,
  .input--filled .input__label-content--hoshi {
  	-webkit-animation: inputLabelAnimation 0.3s forwards;
  	animation: inputLabelAnimation 0.3s forwards;
  }

  $input-hoshi-keyframes-x: 1em;
  $input-hoshi-keyframes-y: -50%;

  @keyframes inputLabelAnimation {
    50% {
  		opacity: 0;
  		-webkit-transform: translate($input-hoshi-keyframes-x, 0);
  		transform: translate($input-hoshi-keyframes-x, 0);
  	}
  	51% {
  		opacity: 0;
  		-webkit-transform: translate(-#{$input-hoshi-keyframes-x}, $input-hoshi-keyframes-y);
  		transform: translate(-#{$input-hoshi-keyframes-x}, $input-hoshi-keyframes-y);
  	}
  	100% {
  		opacity: 1;
  		-webkit-transform: translate(0, $input-hoshi-keyframes-y);
  		transform: translate(0, $input-hoshi-keyframes-y);
  	}
  }
}