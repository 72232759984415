// out: false, main: ../style.scss
// Vars
$measure-sm: $measure--sm;
$measure-md: $measure--md;
$measure-lg: $measure--lg;
$measure-xl: $measure--xl;

// Helper Functions / Mixins
@function strip-unit($num) {
  @return $num / ($num * 0 + 1); 
}
@function calc-rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
@function calc-rem-to-px($size) {
  $remSize: strip-unit($size) * 16px;
  @return #{$remSize}rem;
}
@mixin calc-space($max-size, $min-size, $type, $direction){
  // Erzeugt ein responsives Padding / Margin mit Richtungsangabe (top, right, bottom, left)
  // Rechnung: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
  #{$type}-#{$direction}: calc(#{$min-size} + #{((strip-unit($max-size) * 16) - (strip-unit($min-size)) * 16)} * ((100vw - #{calc-rem(320px)}) / (#{strip-unit(120em) * 16} - 320))); 
}
@function url-friendly-colour($colour) {
  @return '%23' + str-slice('#{$colour}', 2, -1);
}

///////////////////////////////////////
///////////// Object Fit //////////////
///////////////////////////////////////
.objectfit-el {
  overflow: hidden;
  &.compat-object-fit {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .objectfit-picture {
      display: none;
      .objectfit-img {
        display: none;
        object-fit: none !important;
      }
    }
  }
  .objectfit-img { 
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
  }
}
///////////////////////////////////////
///////////// Measures ////////////////
///////////////////////////////////////
.measure {
  &--sm {
    max-width: $measure-sm;
    margin: 0 auto;
  }
  &--md {
    max-width: $measure-md;
    margin: 0 auto;
  }
  &--lg {
    max-width: $measure-lg;
    margin: 0 auto;
  }
  &--xl {
    max-width: $measure-xl;
    margin: 0 auto;
  }
}
/*
.measure-space {
  @media screen and (max-width: $viewport-m){
    padding: 0 $p1--i;
  }
  &--md {
    @media screen and (max-width: $viewport-l){
      padding: 0 $p1--o;
    }
    @media screen and (max-width: $viewport-m){
      padding: 0 $p1--i;
    }
  }
  &--lg {
    padding: 0 $p1--o;
    @media screen and (max-width: $viewport-m){
      padding: 0 $p1--i;
    }
  }
  &--lg-min {
    @media screen and (min-width: $viewport-l){
      padding: 0 $p2;
    }
  }
}*/
/*
.measure-space, .measure-space--lg {
  
  padding-left: $p1--i;
  padding-right: $p1--i;
  @media screen and (min-width: $viewport-l){
    padding-left: $p1--o;
    padding-right: $p1--o;
  }
  &.shadow-content {
    padding-left: $p1--o;
    padding-right: $p1--o;
  }
}
.measure-space--lg {
  @media screen and (max-width: $viewport-l){
    padding-left: 0;
    padding-right: 0;
  }
}
*/
$measure-space: 1.875rem; // Standard-Abstand 

$measure-space2: calc(1.875rem * 2);
$measure-space-m: 3.5rem;

.measure-space {
  padding-left: $measure-space;
  padding-right: $measure-space;
  &.shadow-content {
    padding: 0;
  }
  @media screen and (min-width: $viewport-l){
    padding-left: $measure-space-m;
    padding-right: $measure-space-m;
  }
}

///////////////////////////////////////
/////////// Hide Class ////////////////
///////////////////////////////////////
.hide-block {
  &--sm {
    display: none;
    @media (min-width: $viewport-m){
      display: block; 
    }
  }
  &--md {
    display: none;
    @media (min-width: $viewport-l){
      display: block; 
    }
  }
  &--lg {
    display: none;
    @media (min-width: $viewport-xl){
      display: block; 
    }
  }
}
.hide-inline-block {
  &--sm {
    display: none;
    @media (min-width: $viewport-m){
      display: inline-block; 
    }
  }
  &--md {
    display: none;
    @media (min-width: $viewport-l){
      display: inline-block; 
    }
  }
  &--lg {
    display: none;
    @media (min-width: $viewport-xl){
      display: inline-block;
    }
  }
}
///////////////////////////////////////
/////////// Float Img /////////////////
/////////////////////////////////////// 
.float-img {
  &--left {
    max-width: 100%; 
    height: auto; 
    margin-top: 5px;
    img {
      display: block;
    }
    @media (min-width: $viewport-l){
      max-width: 60%; 
      float: left;
      margin-right: 2rem;
    }
  }
  &--right {
    max-width: 100%; 
    height: auto; 
    margin-top: 5px;
    img {
      display: block;
    }
    @media (min-width: $viewport-l){
      max-width: 60%; 
      float: right;
      margin-left: 2rem;
    }
  }
}

.clear {
  &--left {
    clear: left;
  }
  &--right {
    clear: right;
  }
}
///////////////////////////////////////
///////// Standard Boxshadow //////////
/////////////////////////////////////// 
.shadow {
  box-shadow:0 0 15px 0 rgba(0, 0, 0, 0.2);
} 
///////////////////////////////////////
/////////////// Columns ///////////////
/////////////////////////////////////// 
.columns {
  column-count: auto;
  column-gap: 2rem;
  columns: 19rem;
}

.inline{display: inline}
.inline-block{display:inline-block}
.block{display:block}

.relative { position: relative; }

//ANCHOR BREITEN IN PROZENT

.w100 {
  width:100%;
}
.w90 {
  width:90%;
}
.w80 {
  width:80%;
}
.w70 {
  width:70%;
}
.w60 {
  width:60%;
}
.w50 {
  width:50%;
}
.w40 {
  width:40%;
}
.w30 {
  width:30%;
}
.w20 {
  width:20%;
}
.w10 {
  width:10%;
}

// ANCHOR MELDUNGEN

.success, .warning, .error, .notice {
  font-weight: bold;
  text-align: center;
  padding: $padding-notification;
  margin: $margin-notification;
  border-radius: $borderradius-notification;
}
.success {
  background: $color--sucess;
  color: $font-color--sucess;
  border: $border--sucess;
}
.warning {
  background: $color--warning;
  color: $font-color--warning;
  border: $border--warning;
}
.error {
  background: $color--error;
  color: $font-color--error;
  border: $border--error;
}
.notice {
  background: $color--notice;
  color: $font-color--notice;
  border: $border--notice;
}