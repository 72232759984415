// out: false, main: ../style.scss

.split-modul {
  .text-container {
    padding-top: 1rem;
    @media (min-width: $viewport-xl){
      padding-top: 0;
      padding-right: 2rem;
      padding-left: 2rem;
    }
    p:first-child {
      margin-top: -.3em;
    }
  }
}