// out: false, main: ../style.scss
.miniatures-swiper-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  .gallery-top {
    width: 100%;
    .product-button-next {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
    }
    .product-button-prev {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
    }
  }
  .gallery-thumbs {
    box-sizing: border-box;
    padding: 10px 0;
    .swiper-slide {
      width: 25%;
      opacity: 0.4;
      cursor: pointer;
      &.swiper-slide-thumb-active {
        opacity: 1;
        cursor: default;
      }
    }
  }
  .swiper-slide {
    position: relative;
    overflow: hidden;
  }
}